import {useState} from 'react';
import {Icon, Modal} from '@ezcater/tapas';
import {faBell, faEnvelope} from '@fortawesome/pro-regular-svg-icons';
import useTranslation from 'next-translate/useTranslation';

import AppBarIconButton from '../AppBarIconButton';
import MenuTooltip from '../Menus/MenuTooltip';
import NotificationsFeedbackButton from './NotificationFeedbackButton';

const NotificationsFeedback: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');

  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const openModal = () => setIsMobileModalOpen(true);
  const closeModal = () => setIsMobileModalOpen(false);

  return (
    <>
      <div className="hidden tablet:block">
        <MenuTooltip
          arrowClasses="right-0"
          className="mr-6"
          content={
            <div className="-right-2.5 w-[325px] rounded-md p-5 text-center">
              <div className="flex justify-center text-peppercorn-600">
                <Icon icon={faEnvelope} size="large" />
              </div>
              <NotificationsFeedbackButton />
            </div>
          }
        >
          <AppBarIconButton aria-label={t('notifications.feedback.label')} icon={faBell} />
        </MenuTooltip>
      </div>

      <div className="block tablet:hidden">
        <AppBarIconButton
          aria-label={t('notifications.feedback.label')}
          icon={faBell}
          onClick={openModal}
        />
        <Modal open={isMobileModalOpen} onClose={closeModal}>
          <div className="align-center right-[-10px] flex flex-col rounded-md p-5 text-center">
            <div className="flex justify-center">
              <Icon icon={faEnvelope} size="large" />
            </div>
            <NotificationsFeedbackButton />
          </div>
        </Modal>
      </div>
    </>
  );
};
export default NotificationsFeedback;
