import {
  CateringMenuConsumerCartFragment,
  CuisineOptionEnum,
  CuratedListName,
  FulfillmentDetailFragment,
  IdentityQuery,
  SearchServiceCapabilitiesTaxonomy,
  SearchSuitabilityTaxonomy,
  TypeaheadDocType,
} from '@/graphql/types';

export const cuisineLinks = [
  {
    category: 'Popular',
    display: 'Breakfast',
    id: CuisineOptionEnum.Breakfast,
    type: TypeaheadDocType.Cuisine,
  },
  {
    category: 'Popular',
    display: 'Pizza',
    id: CuisineOptionEnum.Pizza,
    type: TypeaheadDocType.Cuisine,
  },
  {
    category: 'Popular',
    display: 'Mexican',
    id: CuisineOptionEnum.Mexican,
    type: TypeaheadDocType.Cuisine,
  },
];

export const getExplorationLinks = (showGroupOrderingFilter: boolean) => [
  {
    additionalParams: {
      serviceCapabilitiesTaxonomy: SearchServiceCapabilitiesTaxonomy.GroupOrdering,
    },
    category: 'Exploration',
    display: 'Best for group ordering',
    featureFlag: showGroupOrderingFilter,
    id: SearchServiceCapabilitiesTaxonomy.GroupOrdering,
    type: 'OTHER',
  },
  {
    additionalParams: {reliabilityRockstar: true},
    category: 'Exploration',
    display: 'Reliability Rockstars',
    featureFlag: true,
    id: CuratedListName.ReliabilityRockstar,
    type: 'OTHER',
  },
  {
    additionalParams: {suitabilityTaxonomy: SearchSuitabilityTaxonomy.NewBusiness},
    category: 'Exploration',
    display: 'New to ezcater',
    featureFlag: false,
    id: SearchSuitabilityTaxonomy.NewBusiness,
    type: 'OTHER',
  },
];

type TrackingPropTypes = {
  consumerCart: CateringMenuConsumerCartFragment | null;
  fulfillmentDetail: FulfillmentDetailFragment | null;
  identityData: IdentityQuery | null;
};

export const getTrackingProperties = ({
  consumerCart,
  fulfillmentDetail,
  identityData,
}: TrackingPropTypes) => {
  return {
    city: fulfillmentDetail?.address?.city ?? null,
    consumer_cart_id: consumerCart?.id ?? null,
    corpAccountId: identityData?.me?.consumerAccount?.corporateAccount?.id ?? null,
    eventDate: fulfillmentDetail?.eventOn ?? null,
    eventName: fulfillmentDetail?.eventName ?? null,
    eventTime: fulfillmentDetail?.eventLocalTime ?? null,
    fulfillment_detail_id: fulfillmentDetail?.id,
    headcountNumber: fulfillmentDetail?.headcount ?? null,
    orderType: fulfillmentDetail?.strategy,
    state: fulfillmentDetail?.address?.state ?? null,
    street: fulfillmentDetail?.address?.street ?? null,
  };
};
