import useTranslation from 'next-translate/useTranslation';

import useNavLinkTracking from '../hooks/useNavlinkTracking';

const NotificationsFeedbackButton: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');
  const {trackLink} = useNavLinkTracking();

  const openFeedbackEmail = () => {
    const path = 'mailto:support@ezcater.com';
    trackLink(path, 'Give Us Feedback');
    window.location.href = path;
  };

  return (
    <>
      <div className="my-4 text-peppercorn-700">{t('notifications.feedback.text')}</div>
      <button
        type="button"
        onClick={openFeedbackEmail}
        className="whitespace-nowrap rounded border border-blueberry-300 bg-blueberry-300 px-4 py-1.5 font-bold leading-5 text-white hover:border-blueberry-400 hover:bg-blueberry-400 focus:border-blueberry-500 focus:bg-blueberry-500 focus:ring-2 focus:ring-peppercorn-800 active:border-blueberry-600 active:bg-blueberry-600"
      >
        {t('notifications.feedback.button')}
      </button>
    </>
  );
};

export default NotificationsFeedbackButton;
