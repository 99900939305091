import {forwardRef} from 'react';
import {Button, Icon} from '@ezcater/tapas';
import {faAngleDown, faCircleUser} from '@fortawesome/pro-regular-svg-icons';
import useTranslation from 'next-translate/useTranslation';

import {formatPoints} from '../utils';

type UserProfileButtonProps = {
  balance?: number | null;
  balanceEnabled: boolean;
  firstName: string;
};

type Ref = HTMLButtonElement;

const UserProfileButton = forwardRef<Ref, UserProfileButtonProps>(
  ({balance, balanceEnabled, firstName}, ref) => {
    const {t} = useTranslation('app-bar');

    return (
      <Button
        className="ml-2 flex h-12 min-w-36 max-w-56 items-center justify-between border-peppercorn-200 p-2"
        data-testid="profile-nav-bar-item"
        ref={ref}
        variant="outlined"
      >
        <div className="flex items-center gap-2 overflow-hidden">
          <Icon className="shrink-0" icon={faCircleUser} size="small" />

          <div className="flex flex-col items-start overflow-hidden">
            <div className="w-full truncate whitespace-nowrap text-left text-sm">{firstName}</div>
            {balanceEnabled && (
              <div className="text-ellipsis whitespace-nowrap text-xs text-kiwi-400">
                {t('user.pts', {balance: formatPoints(balance || 0)})}
              </div>
            )}
          </div>
        </div>

        <Icon className="shrink-0" icon={faAngleDown} size="small" />
      </Button>
    );
  },
);

export default UserProfileButton;
