import FeatureFlags from '@/FeatureFlags';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIdentity from '@/hooks/useIdentity';

const useOrdererConnectionsCheck = () => {
  const featureFlagEnabled = useFeatureFlag(FeatureFlags.CorporateConnections);
  const {data: identityData, loading} = useIdentity();
  const currentUserVerified = identityData?.me?.consumerAccount?.corporateAccountVerified ?? false;
  const ordererConnectionsEnabled = featureFlagEnabled && currentUserVerified;

  return {ordererConnectionsEnabled, loading};
};

export default useOrdererConnectionsCheck;
