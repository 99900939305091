import {forwardRef, useMemo} from 'react';
import {Button, Icon} from '@ezcater/tapas';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import {twMerge} from 'tailwind-merge';

type AppBarIconButtonProps = React.ComponentProps<typeof Button> & {
  hasRedDot?: boolean;
  hasRedBadge?: boolean;
  icon: IconDefinition;
  numberOfDraftOrders?: number;
};

type Ref = HTMLButtonElement;

const AppBarIconButton = forwardRef<Ref, AppBarIconButtonProps>(
  ({hasRedDot, hasRedBadge, icon, numberOfDraftOrders, ...props}, ref) => {
    const numberToShow = useMemo(() => {
      if (numberOfDraftOrders && numberOfDraftOrders >= 9) {
        return '9+';
      }
      return `${numberOfDraftOrders}`;
    }, [numberOfDraftOrders]);

    return (
      <Button
        ref={ref}
        variant="secondary"
        {...props}
        className={twMerge('p-1.5 font-normal no-underline', props?.className)}
      >
        <Icon icon={icon} size="medium" />
        {hasRedDot && (
          <span
            className="absolute -right-2 -top-2 block h-4 w-4 rounded-full border-2 border-white bg-guava-300"
            data-testid="red-dot"
          />
        )}
        {hasRedBadge && (
          <span
            className={twMerge(
              'flex h-5 w-5 items-center justify-center rounded-full bg-guava-300 p-2 font-bold text-white',
              numberOfDraftOrders && numberOfDraftOrders >= 9 ? 'text-xs' : 'text-sm',
            )}
            data-testid="red-badge"
          >
            {numberToShow}
          </span>
        )}
      </Button>
    );
  },
);

export default AppBarIconButton;
