import {Tooltip} from '@ezcater/tapas';
import {twMerge} from 'tailwind-merge';

type MenuTooltipProps = React.ComponentProps<typeof Tooltip> &
  React.PropsWithChildren<{
    ariaLabel?: string;
    arrowClasses?: string;
    content: React.ReactNode;
    contentClasses?: string;
  }>;

const MenuTooltip: React.FC<MenuTooltipProps> = ({
  ariaLabel = 'menu dropdown',
  arrowClasses,
  children,
  content,
  contentClasses,
  ...props
}) => (
  <Tooltip
    aria-label={ariaLabel}
    content={
      <>
        <div
          className={twMerge(
            'absolute -top-2 right-6 h-4 w-4 -translate-x-1/2 rotate-45 transform border-l border-t bg-white',
            arrowClasses,
          )}
        />
        <div className={twMerge('overflow-auto', contentClasses)}>{content}</div>
      </>
    }
    hideArrow
    placement="bottom-end"
    {...props}
    className={twMerge('z-1300 rounded-xl border bg-white p-6 shadow-outer', props?.className)}
  >
    {children}
  </Tooltip>
);

export default MenuTooltip;
