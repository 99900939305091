import {Button, Icon} from '@ezcater/tapas';
import {faAngleDown, type IconDefinition} from '@fortawesome/pro-light-svg-icons';
import {twMerge} from 'tailwind-merge';

import Popup from '@/components/Popup';

type EventBarPopupProps = React.ComponentProps<typeof Button> & {
  clickHandler: any;
  defaultOpen?: boolean;
  icon: IconDefinition;
  isOpen: boolean;
  label: string;
  popupClasses?: string;
};

const EventBarPopup: React.FC<EventBarPopupProps> = ({
  children,
  clickHandler,
  icon,
  isOpen,
  label,
  popupClasses,
  ...props
}) => {
  const tailwindClasses = {
    active: 'active:border-ezgreen-600 active:bg-ezgreen-600',
    base: 'flex w-44 items-center gap-2 border-peppercorn-200 px-3 py-2 font-normal',
    focus:
      'focus:z-1 focus:border-ezgreen-600 focus:bg-ezgreen-600 focus:text-white focus:hover:border-ezgreen-600 focus:hover:bg-ezgreen-600',
    hover: 'hover:border-ezgreen-400 hover:bg-ezgreen-400 hover:text-white',
    open: 'z-1 border-ezgreen-600 bg-ezgreen-600 text-white hover:border-ezgreen-600 hover:bg-ezgreen-600',
  };

  return (
    <Popup
      isControlled
      offset={15}
      onPopupToggle={clickHandler}
      open={isOpen}
      placement="bottom-start"
      slotProps={{popup: {className: twMerge('w-[460px]', popupClasses)}}}
      trigger={
        <Button
          aria-label={label}
          title={label}
          variant="outlined"
          {...props}
          className={twMerge(
            tailwindClasses.base,
            tailwindClasses.hover,
            tailwindClasses.focus,
            tailwindClasses.active,
            isOpen && tailwindClasses.open,
            props?.className,
          )}
        >
          <div className="flex w-full items-center justify-between gap-2">
            <div className="flex w-[85%] items-center gap-2">
              <Icon className="shrink-0" icon={icon} />
              <div className="truncate whitespace-nowrap">{label}</div>
            </div>
            <div>
              <Icon className="shrink-0" icon={faAngleDown} />
            </div>
          </div>
        </Button>
      }
    >
      {children}
    </Popup>
  );
};

export default EventBarPopup;
