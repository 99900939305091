import {forwardRef, useId} from 'react';
import {Modal, Typography} from '@ezcater/tapas';
import {twJoin, twMerge} from 'tailwind-merge';

type StickyModalProps = React.PropsWithChildren<{
  footer?: React.ReactNode;
  header: React.ReactNode;
  hideShadow?: boolean;
  id?: string;
  onDismiss: () => void;
  open?: boolean;
  slotProps?: {
    body?: React.ComponentPropsWithoutRef<'div'>;
    closeButton?: React.ComponentPropsWithoutRef<'button'>;
    content?: React.ComponentPropsWithoutRef<'div'>;
    header?: React.ComponentPropsWithoutRef<'div'>;
    footer?: React.ComponentPropsWithoutRef<'div'>;
    root?: React.ComponentPropsWithoutRef<typeof Modal>;
  };
}>;

type Ref = HTMLElement;

const StickyModal = forwardRef<Ref, StickyModalProps>(
  (
    {
      children,
      footer,
      header,
      hideShadow = false,
      onDismiss,
      open = false,
      slotProps = {},
      id: propId,
      ...props
    },
    ref,
  ) => {
    const reactId = useId();
    const id = propId || reactId;

    return (
      <Modal
        aria-labelledby={header ? id : undefined}
        open={open}
        onClose={onDismiss}
        ref={ref}
        {...props}
        slotProps={{
          closeButton: {'aria-label': 'close modal', ...slotProps?.closeButton},
          content: {
            ...slotProps?.content,
            className: twJoin(
              'overflow-y-visible p-0 tablet:h-auto',
              slotProps?.content?.className,
            ),
          },
        }}
      >
        <div className="flex h-full flex-col overflow-y-auto text-peppercorn-800">
          {header && (
            <div
              {...slotProps?.header}
              className={twMerge('border-b p-6', slotProps?.header?.className)}
            >
              <Typography className="pr-10" id={id} variant="heading-sm">
                {header}
              </Typography>
            </div>
          )}

          <div
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0} // Required for keyboard scroll
            {...slotProps?.body}
            className={twMerge(
              'flex-1 overflow-y-auto px-8 py-6 focus-within:outline-peppercorn-800',
              slotProps?.body?.className,
            )}
          >
            {children}
          </div>

          {footer && (
            <div
              {...slotProps?.footer}
              className={twMerge(
                'px-8 py-6',
                !hideShadow && 'shadow-top',
                slotProps?.footer?.className,
              )}
            >
              {footer}
            </div>
          )}
        </div>
      </Modal>
    );
  },
);

export default StickyModal;
