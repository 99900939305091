import {cloneElement, useEffect, useRef} from 'react';
import {useLatest} from 'react-use';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  type OffsetOptions,
  useFloating,
} from '@floating-ui/react';
import {ClickAwayListener, Unstable_Popup as MuiPopup} from '@mui/base';
import {AnimatePresence} from 'motion/react';
import * as motion from 'motion/react-m';
import {twMerge} from 'tailwind-merge';

type PopupProps = React.PropsWithChildren<{
  animationProps?: React.ComponentProps<typeof motion.div>;
  hideArrow?: boolean;
  id?: string;
  shouldAutoUpdate?: boolean;
  isControlled?: boolean;
  offset?: OffsetOptions;
  onPopupToggle: (open: boolean) => void;
  open: boolean;
  placement?: React.ComponentProps<typeof MuiPopup>['placement'];
  slotProps?: {
    arrow?: Omit<React.ComponentProps<typeof FloatingArrow>, 'context'>;
    popup?: React.ComponentProps<typeof MuiPopup>;
    popupCard?: React.ComponentProps<'div'>;
    root?: React.ComponentProps<'div'>;
  };
  trigger: React.ReactElement;
  triggerTabIndex?: number | null;
}>;

const Popup: React.FC<PopupProps> = ({
  animationProps,
  children,
  hideArrow = false,
  id = 'popup',
  shouldAutoUpdate = false,
  isControlled,
  offset: offsetProps = hideArrow ? 0 : 10,
  onPopupToggle,
  open,
  placement = 'bottom',
  slotProps = {},
  trigger,
  triggerTabIndex = 0,
  ...props
}) => {
  const arrowRef = useRef<SVGSVGElement | null>(null);

  const {refs, floatingStyles, context} = useFloating({
    middleware: [arrow({element: arrowRef}), flip(), offset(offsetProps)],
    onOpenChange: onPopupToggle,
    open,
    placement,
    strategy: 'fixed',
    whileElementsMounted: shouldAutoUpdate ? autoUpdate : undefined,
  });

  const triggerElement = cloneElement(trigger, {
    onClick: (event: React.MouseEvent<HTMLElement>) => {
      if (trigger.props.onClick) trigger.props.onClick(event);
      onPopupToggle(!open);
    },
    onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
      if (trigger.props.onKeyDown) trigger.props.onKeyDown(event);
      if (event.key === 'Escape') onPopupToggle(false);
    },
    tabIndex: triggerTabIndex,
    type: 'button',
    ref: refs.setReference,
  });

  const onPopupToggleRef = useLatest(onPopupToggle);
  useEffect(() => {
    if (!isControlled) onPopupToggleRef.current?.(open);
  }, [isControlled, open, onPopupToggleRef]);

  return (
    <div {...props} {...slotProps?.root}>
      {triggerElement}
      <AnimatePresence>
        {open && Boolean(refs.reference.current) && (
          <ClickAwayListener
            onClickAway={() => {
              if (!isControlled) onPopupToggle(false);
            }}
          >
            <MuiPopup
              anchor={refs.reference.current as HTMLElement}
              disablePortal
              id={open ? id : undefined}
              open={open}
              onKeyDown={event => {
                if (event.key === 'Escape') onPopupToggle(false);
              }}
              ref={refs.setFloating}
              style={floatingStyles}
              tabIndex={-1}
              {...(slotProps?.popup as React.HTMLAttributes<HTMLDivElement>)}
              className={twMerge('z-1300', slotProps?.popup?.className)}
            >
              <motion.div
                animate={{opacity: 1}}
                className="relative z-1300 rounded-xl border shadow-outer"
                exit={{opacity: 0}}
                initial={{opacity: 0}}
                transition={{duration: 0.1}}
                {...animationProps}
              >
                <>
                  {!hideArrow && (
                    <FloatingArrow
                      context={context}
                      fill="white"
                      height={12}
                      ref={arrowRef}
                      tipRadius={2}
                      width={20}
                      {...slotProps?.arrow}
                    />
                  )}

                  <div
                    {...slotProps?.popupCard}
                    className={twMerge(
                      'rounded-[11px] bg-white p-6 text-peppercorn-800',
                      slotProps?.popupCard?.className,
                    )}
                  >
                    {children}
                  </div>
                </>
              </motion.div>
            </MuiPopup>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Popup;
