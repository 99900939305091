import {createContext, useContext, useMemo, useState} from 'react';

type MobileCartContextValues = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
} | null;

export const MobileCartContext = createContext<MobileCartContextValues>(null);

export const useMobileCartContext = () => useContext(MobileCartContext);

const MobileCartProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const providerValue = useMemo(
    () => ({isModalOpen, setIsModalOpen}),
    [isModalOpen, setIsModalOpen],
  );

  return <MobileCartContext.Provider value={providerValue}>{children}</MobileCartContext.Provider>;
};

export default MobileCartProvider;
