import {faCartShopping} from '@fortawesome/pro-regular-svg-icons';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import {useStoreNextRouter} from '@/components/StoreNextRouter';
import useIsMobileCheck from '@/hooks/useIsMobileCheck';
import appsFlyerDismissal from '@/utils/appsFlyerDismissal';
import AppBarIconButton from '../AppBarIconButton';

type CartButtonProps = {
  buttonRef?: React.Ref<HTMLButtonElement>;
  cartHasItems?: boolean;
  hasCarts?: boolean;
  menuPageClickHandler?: () => void;
  numberOfDraftOrders?: number;
  onClick?: () => void;
  showEz490?: boolean;
};

const cartButtonStyles = {
  populated:
    'border-green-110 bg-green-110 text-green-90 hover:border-green-110 hover:bg-green-110 focus:border-green-110 focus:bg-green-110 active:border-green-110 active:bg-green-110',
};

const CartButton: React.FC<CartButtonProps> = ({
  hasCarts,
  menuPageClickHandler,
  numberOfDraftOrders,
  onClick,
  showEz490,
}) => {
  const {t} = useTranslation('app-bar');
  const mobileClickHandler = menuPageClickHandler || onClick;
  const {isOnMenuPage} = useStoreNextRouter();
  const isMobile = useIsMobileCheck();

  const handleMobileClick = () => {
    appsFlyerDismissal();
    mobileClickHandler?.();
  };

  return (
    <AppBarIconButton
      icon={faCartShopping}
      type="button"
      data-testid="cart-button-component"
      aria-label={t('cart.label')}
      title={t('cart.label')}
      onClick={handleMobileClick}
      className={twJoin(
        hasCarts && showEz490 && 'gap-1',
        (!hasCarts && showEz490) || (showEz490 && isOnMenuPage && !isMobile)
          ? 'bg-peppercorn-100'
          : hasCarts && cartButtonStyles.populated,
      )}
      hasRedDot={hasCarts && !showEz490}
      hasRedBadge={hasCarts && showEz490 && !isOnMenuPage && !isMobile}
      numberOfDraftOrders={numberOfDraftOrders}
    />
  );
};

export default CartButton;
