import {type ReactElement} from 'react';
import useTranslation from 'next-translate/useTranslation';

const SkipNav = (): ReactElement => {
  const {t} = useTranslation('app-bar');

  return (
    <nav>
      <ul className="absolute left-0 top-0 m-0 list-none p-0">
        <li className="flex no-underline">
          <a
            className="absolute -left-96 w-36 text-center font-normal no-underline focus:left-2 focus:z-10 focus:inline-block focus:bg-blue-800 focus:text-white active:left-2 active:z-10 active:inline-block active:bg-blue-800 active:text-white"
            href="#main-content"
          >
            {t('navLinks.skipNav')}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default SkipNav;
