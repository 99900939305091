import {useCallback, useMemo, useRef, useState} from 'react';
import {useDebounce} from 'react-use';
import {Button, Icon, tapasClassNames, TextField} from '@ezcater/tapas';
import {faArrowTrendUp, faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {ClickAwayListener} from '@mui/base';
import Fuse from 'fuse.js';
import * as motion from 'motion/react-m';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import {useFulfillmentDetailState} from '@/components/FulfillmentDetailStateProvider/FulfillmentDetailStateProvider';
import Experiments from '@/Experiments';
import FeatureFlags from '@/FeatureFlags';
import {
  CuisineOptionEnum,
  SearchServiceCapabilitiesTaxonomy,
  SearchSuitabilityTaxonomy,
} from '@/graphql/types';
import useExperiment from '@/hooks/useExperiment';
import useEz486RemoveWFHSearchBarAndRecentAddresses from '@/hooks/useEz486RemoveWFHSearchBarAndRecentAddresses';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIdentity from '@/hooks/useIdentity';
import useMediaQuery from '@/hooks/useMediaQuery';
import useTracking from '@/hooks/useTracking';
import {useConsumerCart, useDerivedMenuData} from '@/pageComponents/catering-menu/hooks';
import {
  allOtherCuisines,
  mostPopularCuisines,
} from '@/pageComponents/search-order-id/Filters/utils/cuisines';
import {
  FiltersUpdateSource,
  KeywordSearchCuisine,
} from '@/pageComponents/search-order-id/Filters/utils/types';
import useFilters from '@/pageComponents/search-order-id/Filters/utils/useFilters';
import {SEARCH_NEW_PATH, SEARCH_ORDER_ID_PATH} from '@/paths';
import {compilePath} from '@/utils';
import {geocodedAddressResultToStartNewSearchAddressParams} from '@/utils/geocodedAddressResult';
import SearchLink from './SearchLink';

const CUISINE_OPTIONS = [...mostPopularCuisines, ...allOtherCuisines];
type SearchBarProps = {
  isAdmin?: boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({isAdmin = false, isOpen, setIsOpen}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const useShortPlaceholder = useMediaQuery('(max-width: 1439px)') || isAdmin;
  const {t} = useTranslation('app-bar');
  const showGroupOrderingFilter = useFeatureFlag(FeatureFlags.GroupOrderingSearchFilter);
  const {filters, updateFilters} = useFilters();
  const [workingValue, setWorkingValue] = useState(filters.keyword);
  const {fulfillmentDetail} = useFulfillmentDetailState();
  const {track} = useTracking();
  const consumerCart = useConsumerCart();
  const {caterer} = useDerivedMenuData();
  const {data: identityData} = useIdentity();
  // We should swap this with the global fulfillment detail address when it's available
  const userRecommendedDefaultAddress = identityData?.identity?.recommendedDefaultAddress;
  const {trackExposure: trackez489Exposure} = useExperiment(Experiments.TypeaheadSearch);

  const {id: catererId = null} = caterer ?? {};

  const addressParams = useMemo(() => {
    return userRecommendedDefaultAddress
      ? geocodedAddressResultToStartNewSearchAddressParams(userRecommendedDefaultAddress)
      : {};
  }, [userRecommendedDefaultAddress]);

  const getSearchLinkHref = useCallback(
    (cuisineOption = '', additionalParams = {}) => {
      if (fulfillmentDetail?.id)
        return compilePath(
          SEARCH_ORDER_ID_PATH,
          {orderId: fulfillmentDetail.id},
          {cuisineOption, ...additionalParams},
          {skipEmptyString: true, skipNull: true},
        );

      return compilePath(
        SEARCH_NEW_PATH,
        {},
        {...addressParams, cuisineOption, ...additionalParams},
        {skipEmptyString: true, skipNull: true},
      );
    },
    [addressParams, fulfillmentDetail?.id],
  );

  const trackingProperties = {
    city: fulfillmentDetail?.address?.city || null,
    consumer_cart_id: consumerCart?.id || null,
    corpAccountId: identityData?.me?.consumerAccount?.corporateAccount?.id || null,
    eventDate: fulfillmentDetail?.eventOn || null,
    eventName: fulfillmentDetail?.eventName || null,
    eventTime: fulfillmentDetail?.eventLocalTime || null,
    fulfillment_detail_id: fulfillmentDetail?.id,
    headcountNumber: fulfillmentDetail?.headcount || null,
    orderType: fulfillmentDetail?.strategy,
    state: fulfillmentDetail?.address?.state || null,
    street: fulfillmentDetail?.address?.street || null,
  };

  const handleSearch = (iconClicked = false, event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    setIsOpen(false);
    searchRef.current?.blur();
    updateFilters({keyword: workingValue}, FiltersUpdateSource.Keyword, false);

    if (iconClicked) {
      track('search-icon-clicked', {
        caterer_id: catererId,
        misc_json: JSON.stringify({...trackingProperties}),
        page: window.location.pathname,
        sub_category: 'navigation bar',
      });
    } else {
      track('search-button-clicked', {
        caterer_id: catererId,
        misc_json: JSON.stringify({...trackingProperties}),
        page: window.location.pathname,
        sub_category: 'navigation bar',
      });
    }
  };

  const fuzzySearch = useMemo(() => {
    const options = {
      keys: ['name'],
      threshold: 0.2,
    };

    const cuisineOptions = Object.entries(CUISINE_OPTIONS).map(pair => ({
      name: t(`search.taxons.cuisineTaxonomy.${pair[1]}.label`),
      value: pair[1],
      type: 'cuisine',
    }));
    // threshold is set to 0.2 to allow for some flexibility in matching
    return new Fuse(cuisineOptions, options);
  }, [t]);

  const matches = useMemo(
    () => (workingValue.length < 3 ? [] : fuzzySearch.search(workingValue).map(({item}) => item)),
    [fuzzySearch, workingValue],
  );

  const cuisineMatches = matches.filter(
    match => match.type === 'cuisine',
  ) as KeywordSearchCuisine[];

  const options = useMemo(() => {
    const trackCuisineSuggestion = (result: {name: any}) => {
      track('clicked-view-cuisine-suggestion', {
        caterer_id: catererId,
        misc_json: JSON.stringify({
          clickedValue: result.name,
          keyword: workingValue,
          suggestions: cuisineMatches.map(option => option.value),
        }),
        page: window.location.pathname,
        sub_category: 'navigation bar',
      });
    };

    return workingValue.length < 3
      ? []
      : [
          {
            group: t('search.keyword.suggestions.keyword'),
            name: t('search.keyword.suggestions.keywordSearch', {keyword: workingValue}),
            value: workingValue,
          },
          ...cuisineMatches.map((result: any) => ({
            group: t('search.keyword.suggestions.cuisines'),
            name: (
              <SearchLink
                href={getSearchLinkHref(result.value)}
                label={result.name}
                tag={result.type}
                onClick={() => trackCuisineSuggestion(result)}
              />
            ),
            value: result.value,
          })),
        ];
  }, [workingValue, t, cuisineMatches, getSearchLinkHref, catererId, track]);

  const cuisineLinks = [
    {
      label: 'Breakfast',
      tag: 'Cuisines',
      category: 'Popular',
      cuisineOption: CuisineOptionEnum.Breakfast,
    },
    {
      label: 'Pizza',
      tag: 'Cuisines',
      category: 'Popular',
      cuisineOption: CuisineOptionEnum.Pizza,
    },
    {
      label: 'Mexican',
      tag: 'Cuisines',
      category: 'Popular',
      cuisineOption: CuisineOptionEnum.Mexican,
    },
  ];

  const explorationLinks = [
    {
      label: 'Best for group ordering',
      category: 'Exploration',
      additionalParams: {
        serviceCapabilitiesTaxonomy: SearchServiceCapabilitiesTaxonomy.GroupOrdering,
      },
      featureFlag: showGroupOrderingFilter,
    },
    {
      label: 'Reliability Rockstars',
      category: 'Exploration',
      additionalParams: {reliabilityRockstar: true},
      featureFlag: true,
    },
    {
      label: 'New to ezcater',
      category: 'Exploration',
      additionalParams: {suitabilityTaxonomy: SearchSuitabilityTaxonomy.NewBusiness},
      featureFlag: false,
    },
  ];

  const handleInputTracking = () => {
    if (!isOpen) {
      // control
      trackez489Exposure();
      track('search-bar-clicked', {
        caterer_id: catererId,
        misc_json: JSON.stringify({
          ...trackingProperties,
          suggestions:
            cuisineMatches.length > 0
              ? matches.map(option => ({value: option.value, type: option.type}))
              : [],
          defaultSuggestions: !options?.length
            ? [
                ...cuisineLinks.map(({label, category}) => ({label, category})),
                ...explorationLinks.map(({label, category}) => ({label, category})),
              ]
            : [],
        }),
        page: window.location.pathname,
        sub_category: 'navigation bar',
      });
    }
    setIsOpen(true);
  };

  useDebounce(
    () => {
      if (options.length > 0) {
        track('viewed-cuisine-suggestion', {
          caterer_id: catererId,
          misc_json: JSON.stringify({
            keyword: workingValue,
            suggestions: cuisineMatches.map(option => option.value),
          }),
          page: window.location.pathname,
          sub_category: 'navigation bar',
        });
      }
    },
    300,
    [workingValue],
  );

  const trackRecommendedSearch = (label: string, category: string) => {
    track('recommended-search-clicked', {
      caterer_id: catererId,
      misc_json: JSON.stringify({
        clickedValue: label,
        category: category,
        ...trackingProperties,
      }),
      page: window.location.pathname,
      sub_category: 'navigation bar',
    });
  };

  // ez486
  const {removeWFHSearchBarAndRecentAddresses} = useEz486RemoveWFHSearchBarAndRecentAddresses();

  const handleHoverTracking = () => {
    track('search-icon-hovered', {
      misc_json: JSON.stringify({...trackingProperties}),
      page: window.location.pathname,
      sub_category: 'navigation bar',
    });
  };
  // end ez486

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <motion.div
        className={twJoin(
          'hidden w-auto min-w-[145px] max-w-[800px] bg-white desktop1279:block',
          tapasClassNames.input.border,
          !isOpen && tapasClassNames.focusWithin,
          !isAdmin && 'desktop1440:min-w-[330px]',
          isOpen
            ? 'fixed left-44 top-[18px] z-1300 w-full rounded-xl p-4 pt-2 shadow-outer'
            : '!rounded-full',
        )}
        data-testid="search-bar-v1"
        initial={false}
        key="SearchBar"
        layout
        layoutDependency={isOpen}
        onClick={() => setIsOpen(true)}
        ref={searchRef}
        transition={{layout: {duration: isOpen ? 0.15 : 0}}}
      >
        <TextField
          autoFocus={isOpen}
          className={twJoin(
            'w-full rounded-full border-0 focus-within:ring-0 [&>input]:truncate',
            removeWFHSearchBarAndRecentAddresses && '[&_div:first-of-type]:right-1.5',
          )}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setWorkingValue(event.target.value)
          }
          onFocus={handleInputTracking}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              setIsOpen(!isOpen);
              handleSearch();
            }
          }}
          placeholder={t(`search.input.placeholder.${useShortPlaceholder ? 'short' : 'long'}`)}
          prefix={
            !removeWFHSearchBarAndRecentAddresses && (
              <Icon
                className={isOpen ? 'text-ezgreen-300' : 'text-peppercorn-800'}
                icon={faMagnifyingGlass}
                size="xsmall"
              />
            )
          }
          style={{paddingRight: 0}}
          suffix={
            <>
              {removeWFHSearchBarAndRecentAddresses && !isOpen && (
                <Button
                  aria-label={t('search.input.label')}
                  className="rounded-full p-2"
                  data-testid="search-bar-search-icon"
                  onClick={event => handleSearch(true, event)}
                  onMouseEnter={handleHoverTracking}
                >
                  <Icon className="text-white" icon={faMagnifyingGlass} size="xsmall" />
                </Button>
              )}
              {isOpen && (
                <Button className="-mr-4 px-8" onClick={event => handleSearch(false, event)}>
                  {t('search.input.label')}
                </Button>
              )}
            </>
          }
          value={workingValue}
        />
        {/* Defaults */}
        {isOpen && !options?.length && (
          <div className="mt-2 flex w-[inherit] justify-between gap-4 rounded-none border-t !border-peppercorn-100 bg-white px-4 py-6">
            <div className="flex flex-1 flex-col gap-2">
              <>
                <div className="flex gap-2">
                  <Icon className="text-ezgreen-300" icon={faArrowTrendUp} size="xsmall" />
                  <div className="text-xs font-bold uppercase tracking-wide text-peppercorn-300">
                    {t('search.content.popularSearches.label')}
                  </div>
                </div>

                {cuisineLinks.map(({label, tag, cuisineOption, category}) => (
                  <SearchLink
                    key={label}
                    label={label}
                    tag={tag}
                    href={getSearchLinkHref(cuisineOption)}
                    onClick={() => trackRecommendedSearch(label, category)}
                  />
                ))}
              </>
            </div>

            <div className="flex flex-1 flex-col gap-2">
              <>
                <div className="text-xs font-bold uppercase tracking-wide text-peppercorn-300">
                  {t('search.content.exploration.label')}
                </div>
                {explorationLinks
                  .filter(({featureFlag}) => !!featureFlag)
                  .map(({label, additionalParams, category}) => (
                    <SearchLink
                      key={label}
                      label={label}
                      href={getSearchLinkHref('', additionalParams)}
                      onClick={() => trackRecommendedSearch(label, category)}
                    />
                  ))}
              </>
            </div>

            <div className="flex-1"></div>
          </div>
        )}

        {/* With search results */}
        {isOpen && !!options?.length && (
          <div className="mt-2 flex w-[inherit] flex-col justify-between gap-2 rounded-none border-t !border-peppercorn-100 bg-white px-4 py-6">
            {options.map(opt => opt.name)}
          </div>
        )}
      </motion.div>
    </ClickAwayListener>
  );
};

export default SearchBar;
