import {createContext, useContext} from 'react';

export const DebounceContext = createContext<boolean>(true);

export const useIsDebounceEnabled = () => useContext(DebounceContext);

const DebounceProvider: React.FC<React.PropsWithChildren<{enabled: boolean}>> = ({
  children,
  enabled,
}) => {
  return <DebounceContext.Provider value={enabled}>{children}</DebounceContext.Provider>;
};

export default DebounceProvider;
