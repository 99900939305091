import {FC} from 'react';
import {Button, Icon, Link} from '@ezcater/tapas';
import {faCalendar, faLocationDot} from '@fortawesome/pro-light-svg-icons';
import {faUserGroup} from '@fortawesome/pro-regular-svg-icons';
import {format} from 'date-fns';
import Image from 'next/image';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import useTracking from '@/hooks/useTracking';
import {useConsumerCart} from '@/pageComponents/catering-menu/hooks';
import {CATERING_MENU_PATH} from '@/paths';
import {compilePath} from '@/utils';
import {DateTimeFormats} from '@/utils/dateFormat';
import {Order} from './Cart';
import DeleteCartButton from './DeleteCartButton';

type DraftOrderEz490Props = {
  order: Order;
  setKeepCartOpen: (keepCartOpen: boolean) => void;
};

const DraftOrderEz490: FC<DraftOrderEz490Props> = ({order, setKeepCartOpen}) => {
  const {t} = useTranslation('app-bar');
  const {trackClick} = useTracking();
  const currencyFormatter = useCurrencyFormatter();
  const consumerCart = useConsumerCart();

  const isCurrentOrder = consumerCart?.id === order.id;

  const compiledOrderPath = isCurrentOrder
    ? ''
    : compilePath(CATERING_MENU_PATH, {catererUrlId: order.caterer.urlId}, {cartId: order.id});

  const caterer = order.caterer;
  const formattedDateTime =
    order.eventAt && format(new Date(order.eventAt), DateTimeFormats.DayLongDateWithTime);

  const handleContinueButtonClick = () => {
    trackClick('app-bar-draft-order-continue-order-button');
  };

  const itemsQuantity = order.items.reduce((count, item) => (count += item.quantity), 0);

  return (
    <div className="flex flex-col gap-4">
      <Link
        aria-label={caterer.urlId}
        className={twJoin(
          isCurrentOrder && 'pointer-events-none',
          'group flex w-full flex-col gap-6 rounded-lg text-inherit no-underline outline-black hover:bg-peppercorn-100 hover:text-inherit hover:no-underline',
        )}
        data-testid="draft-order-card"
        href={compiledOrderPath}
        onClick={() => trackClick('app-bar-draft-order-card')}
      >
        <div className="flex w-full flex-col gap-4">
          <div className="relative h-20 w-full">
            <Image
              alt=""
              className="rounded-lg object-cover"
              data-chromatic="ignore"
              fill
              sizes="100vw"
              src={caterer.brand?.heroImage?.mobileHeroUrl || ''}
            />
          </div>

          <div className="flex w-full flex-col gap-2">
            <p className="font-bold">{caterer.name}</p>

            {(order.accountName || order.address || formattedDateTime) && (
              <div className="flex flex-col gap-2">
                {formattedDateTime && (
                  <div className="flex items-center gap-2">
                    <Icon icon={faCalendar} size="xsmall" aria-hidden />
                    <span>{formattedDateTime}</span>
                  </div>
                )}
                {(order.address?.name || order.address?.fullAddress) && (
                  <div className="flex items-center gap-2">
                    <Icon icon={faLocationDot} size="xsmall" aria-hidden />
                    <span className="truncate">
                      {order.address.name ? order.address.name : order.address.fullAddress}
                    </span>
                  </div>
                )}
                {order.accountName && (
                  <div className="flex items-center gap-2">
                    <Icon icon={faUserGroup} size="xsmall" aria-hidden />
                    <span>{order.accountName}</span>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="rounded-lg bg-peppercorn-50 p-3 group-hover:bg-inherit">
            <div className="line-clamp-3">
              {order.items.map((item, index) => (
                <span key={item.id}>
                  <Trans
                    i18nKey={`app-bar:cart.ez490.itemsList`}
                    values={{quantity: item.quantity, itemName: item.name}}
                  />
                  {index < order.items.length - 1 && <span> / </span>}
                </span>
              ))}
            </div>
            <div className="my-2 h-px bg-gray-200"></div>
            <div className="flex justify-between">
              <p className="text-sm">{t('cart.ez490.itemsQuantity', {itemsQuantity})}</p>
              <p className="font-bold">{currencyFormatter(order.totalDue.subunits, 'USD')}</p>
            </div>
          </div>
        </div>
      </Link>
      <div className="flex gap-2">
        {!isCurrentOrder && (
          <DeleteCartButton orderId={order.id} setKeepCartOpen={setKeepCartOpen} />
        )}
        <Link
          href={compiledOrderPath}
          className={twJoin(
            'block h-full w-full grow text-white hover:text-white hover:no-underline',
            isCurrentOrder && 'pointer-events-none',
          )}
        >
          <Button
            disabled={isCurrentOrder}
            className="w-full grow"
            onClick={handleContinueButtonClick}
          >
            {t(`cart.ez490.${isCurrentOrder ? 'currentlyEditing' : 'continueOrder'}`)}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default DraftOrderEz490;
