import {useCallback} from 'react';
import {Link} from '@ezcater/tapas';
import Image from 'next/image';
import {twJoin} from 'tailwind-merge';

import {FulfillmentDetailFragment, TypeaheadDocType} from '@/graphql/types';
import {
  CATERING_MENU_PATH,
  MARKETPLACE_MENU_DATE_PATH,
  SEARCH_NEW_PATH,
  SEARCH_ORDER_ID_PATH,
} from '@/paths';
import {compilePath} from '@/utils';
import {PartialTypeaheadResult} from './types';

type SearchLinkProps = React.ComponentProps<typeof Link> & {
  addressParams: any;
  fulfillmentDetail: FulfillmentDetailFragment | null;
  isDefault?: boolean;
  suggestion: PartialTypeaheadResult;
};

const SearchLink: React.FC<SearchLinkProps> = ({
  addressParams,
  fulfillmentDetail,
  isDefault,
  suggestion,
  ...props
}) => {
  const {eventOn, id: fulfillmentDetailId} = fulfillmentDetail || {};
  const getMenuLink = useCallback(
    (slug: string) => {
      if (eventOn) {
        return compilePath(
          MARKETPLACE_MENU_DATE_PATH,
          {date: eventOn, catererUrlId: slug},
          {fulfillmentDetailId},
        );
      }

      return compilePath(CATERING_MENU_PATH, {catererUrlId: slug}, {fulfillmentDetailId});
    },
    [eventOn, fulfillmentDetailId],
  );

  const getSearchLink = useCallback(
    (suggestion: PartialTypeaheadResult) => {
      const {additionalParams} = suggestion;
      const filterParams: Record<string, string> = {};

      switch (suggestion.type) {
        case TypeaheadDocType.Cuisine:
          filterParams.cuisineOption = suggestion.id;
          break;
        case TypeaheadDocType.Diversity:
          filterParams.deiOwnedBusinessTaxonomy = suggestion.id;
          break;
        default:
          break;
      }

      if (fulfillmentDetail?.id)
        return compilePath(
          SEARCH_ORDER_ID_PATH,
          {orderId: fulfillmentDetail.id},
          {...additionalParams, ...filterParams},
          {skipEmptyString: true, skipNull: true},
        );

      return compilePath(
        SEARCH_NEW_PATH,
        {},
        {...addressParams, ...additionalParams, ...filterParams},
        {skipEmptyString: true, skipNull: true},
      );
    },
    [addressParams, fulfillmentDetail?.id],
  );

  if (suggestion.type === 'CATERER') {
    return (
      <li
        className="py-3 hover:-ml-8 hover:-mr-8 hover:cursor-pointer hover:bg-peppercorn-100 hover:pl-8 hover:pr-8"
        key={suggestion.id}
      >
        {/* Use bare anchor to get proper back button behavior */}
        <a
          className="items flex w-full items-center justify-start gap-2 text-xl text-peppercorn-800 hover:text-peppercorn-800 hover:no-underline active:text-peppercorn-800"
          href={getMenuLink(suggestion.id)}
          tabIndex={0}
          {...props}
        >
          {suggestion.display}
          <Image
            alt=""
            className="rounded border border-peppercorn-200 bg-white p-0.5"
            data-chromatic="ignore"
            height={21}
            width={50}
            src={`${__DEV__ ? 'https://www.ezcater.com' : ''}/db_images/show_img/${suggestion.logoImageId}`}
          />
        </a>
      </li>
    );
  }

  return (
    <li
      className={twJoin(
        'py-3 hover:cursor-pointer hover:bg-peppercorn-100',
        !isDefault && 'hover:-ml-8 hover:-mr-8 hover:pl-8 hover:pr-8',
      )}
      key={props.key}
    >
      {/* Use bare anchor to get proper back button behavior */}
      <a
        className="flex w-full items-center justify-start gap-2 text-xl text-peppercorn-800 hover:text-peppercorn-800 hover:no-underline active:text-peppercorn-800"
        href={getSearchLink(suggestion)}
        tabIndex={0}
        {...props}
      >
        {suggestion.display}

        {suggestion.type !== 'OTHER' && (
          <span className="text-xs font-bold uppercase tracking-wide text-peppercorn-400">
            {suggestion.type}
          </span>
        )}
      </a>
    </li>
  );
};

export default SearchLink;
