type NotificationBadgeProps = {
  unreadNotificationCount?: number;
};

const NotificationBadge: React.FC<NotificationBadgeProps> = ({unreadNotificationCount}) => {
  return (
    <div className="hidden mobile375:block">
      <span className="absolute left-[20px] top-[1px] flex h-[17px] w-[23px] rounded-full border-2 border-white bg-guava-300">
        <span className="m-auto text-[10px] font-extrabold leading-3 text-white">
          {unreadNotificationCount}
        </span>
      </span>
    </div>
  );
};

export default NotificationBadge;
