import {useEffect, useMemo, useState} from 'react';
import {Link, Modal} from '@ezcater/tapas';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import LoadingSpinner from '@/components/Loading/LoadingSpinner';
import Experiments from '@/Experiments';
import {OpenOrdersQuery, useOpenOrdersQuery} from '@/graphql/types';
import useExperiment from '@/hooks/useExperiment';
import useTracking from '@/hooks/useTracking';
import {ORDERS_PATH} from '@/paths';
import {compilePath} from '@/utils';
import RequiredPath from '@/utils/RequiredPath';
import MenuTooltip from '../Menus/MenuTooltip';
import CartButton from './CartButton';
import DraftOrder from './DraftOrder';
import DraftOrderEz490 from './DraftOrderEz490';
import EmptyState from './EmptyState';

export type Order = {
  id: string;
  items: {
    id: string;
    name: string;
    quantity: number;
  }[];
  totalDue: {
    currency: string;
    subunits: number;
  };
  caterer: {
    id: string;
    urlId: string;
    name: string;
    address?: {
      id: string;
      city: string;
      state: string;
      fullAddress?: string;
      nameOrLocation?: string;
      street?: string;
      timeZoneIdentifier?: string;
      uuid?: string;
    };
    brand?: {
      id: string;
      heroImage?: {
        uuid: string;
        mobileHeroUrl?: string | null;
      };
    };
  };
  eventName?: string | null;
  eventAt?: string | null;
  address?: {
    id: string;
    name?: string | null;
    fullAddress?: string | null;
  };
  updatedAt: string;
  accountName?: string;
};

type FulfillmentDetailConnection = RequiredPath<
  OpenOrdersQuery,
  ['identity', 'fulfillmentDetails']
>;

type FulfillmentDetailConnectionConsumerCart = RequiredPath<
  FulfillmentDetailConnection,
  ['edges', number, 'node', 'consumerCarts', number]
>;

type FormatConsumerCartParams = {
  consumerCart: FulfillmentDetailConnectionConsumerCart;
  eventName?: string | null;
  eventAt?: string | null;
  address?: {
    id: string;
    name?: string | null;
    fullAddress?: string;
  };
  updatedAt: string;
  accountName?: string;
};

const formatConsumerCart = ({
  consumerCart,
  eventName,
  eventAt,
  address,
  updatedAt,
  accountName,
}: FormatConsumerCartParams): Order => {
  return {
    eventName,
    eventAt,
    address,
    id: consumerCart.id,
    items: consumerCart.items.map(item => ({
      id: item.id,
      name: item.name ?? '',
      quantity: item.quantity,
    })),
    totalDue: {
      currency: consumerCart.totalDue.currency,
      subunits: consumerCart.totalDue.subunits,
    },
    caterer: {
      ...consumerCart.caterer,
      address: consumerCart.caterer.address ?? undefined,
      brand: consumerCart.caterer.brand
        ? {
            id: consumerCart.caterer.brand.id,
            heroImage: consumerCart.caterer.brand.heroImage ?? undefined,
          }
        : undefined,
    },
    updatedAt,
    accountName,
  };
};

const formatOrders = (fulfillmentDetailConnection?: FulfillmentDetailConnection | null) => {
  if (!fulfillmentDetailConnection?.edges) return [];

  return fulfillmentDetailConnection.edges
    .flatMap(fulfillmentDetailEdge => {
      const fulfillmentDetail = fulfillmentDetailEdge.node;
      if (!fulfillmentDetail?.consumerCarts) return null;

      return fulfillmentDetail.consumerCarts.map(cart =>
        formatConsumerCart({
          consumerCart: cart,
          eventName: fulfillmentDetail.eventName,
          eventAt: fulfillmentDetail.eventAt,
          address: fulfillmentDetail.address,
          updatedAt: cart.updatedAt,
          accountName: fulfillmentDetail?.corpAccount?.name,
        }),
      );
    })
    ?.filter(cart => cart != null);
};

const groupAndSortOrders = (orders: Order[]): Order[] => {
  // Filter out drafts made for the past
  const currentOrders = orders.filter(order => {
    if (!order.eventAt) return true;
    const eventDate = new Date(order.eventAt);
    const now = new Date();

    return eventDate >= now;
  });

  return [...currentOrders].sort((a, b) => {
    // Sorting by last order interaction
    const updatedAtA = new Date(a.updatedAt).getTime();
    const updatedAtB = new Date(b.updatedAt).getTime();

    if (!updatedAtA || !updatedAtB) {
      // Neither has a updatedAt date to sort by, sort by presence of event name
      if (!a.eventName && b.eventName) return -1;
      if (a.eventName && !b.eventName) return 1;
      return 0;
    } else {
      return updatedAtB - updatedAtA;
    }
  });
};

type CartProps = {
  menuPageClickHandler: () => void;
};

const Cart: React.FC<CartProps> = ({menuPageClickHandler}) => {
  const {t} = useTranslation('app-bar');
  const {track, trackClick} = useTracking();
  const {data, loading} = useOpenOrdersQuery();
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const openModal = () => setIsMobileModalOpen(true);
  const closeModal = () => setIsMobileModalOpen(false);
  const [cartIsOpen, setCartIsOpen] = useState(false);
  const [keepCartOpen, setKeepCartOpen] = useState(false);

  const orders = useMemo(() => formatOrders(data?.identity?.fulfillmentDetails), [data]);
  const hasCarts = orders.length > 0;

  const groupedOrders = useMemo(() => groupAndSortOrders(orders), [orders]);
  const showLoadingSpinner = loading && !hasCarts;

  // ez490: Draft Cart Style Updates
  const {variant: ez490Variant, trackExposure: trackExposureEz490} = useExperiment(
    Experiments.DraftCartStyleUpdates,
  );

  const showEz490 = ez490Variant === 'treatment';

  useEffect(() => {
    if (hasCarts) {
      trackExposureEz490();
    }
  }, [hasCarts, trackExposureEz490]);
  // ez490: Draft Cart Style Updates

  let content: React.ReactNode;

  if (showLoadingSpinner) {
    content = <LoadingSpinner />;
  } else if (!hasCarts) {
    content = <EmptyState />;
  } else if (showEz490) {
    content = (
      <div className="flex flex-col gap-4">
        <h3 className="mb-2 hidden font-bold tablet:block">
          {t('cart.ez490.title', {numberDrafts: groupedOrders.length})}
        </h3>
        {groupedOrders.map(order => (
          <div key={order.id}>
            <DraftOrderEz490 order={order} setKeepCartOpen={setKeepCartOpen} />
            <div className="mb-3 mt-7 h-px bg-gray-200"></div>
          </div>
        ))}
        <Link
          className="w-full rounded-lg border border-peppercorn-800 p-2 font-bold text-peppercorn-800 hover:bg-peppercorn-100 hover:text-peppercorn-800 hover:no-underline"
          href={compilePath(ORDERS_PATH, {}, {tab: 'DRAFTS'})}
          onClick={() => trackClick('see-all-drafts-button')}
        >
          {t('cart.ez490.seeAllDrafts')}
        </Link>
      </div>
    );
  } else {
    content = groupedOrders.map((order, index) => (
      <div key={order.id}>
        <DraftOrder order={order} />
        {index < groupedOrders.length - 1 && <div className="my-2 bg-gray-200"></div>}
      </div>
    ));
  }

  const handleCartOpenStateChange = (isOpen: boolean) => {
    // If keepCartOpen is true, override and keep the cart open
    // Otherwise, use the cart's original isOpen status
    // Added as part of ez490
    setCartIsOpen(keepCartOpen || isOpen);
  };

  return (
    <>
      <div className="hidden tablet:block">
        <MenuTooltip
          arrowClasses="right-0"
          className={twJoin('mr-8', showEz490 && 'w-[362px] px-3')}
          content={
            <div className={twJoin('text-black', showEz490 ? 'w-full px-1' : 'w-[260px]')}>
              {content}
            </div>
          }
          placement="bottom-start"
          contentClasses={showEz490 ? 'max-h-[640px]' : 'max-h-[500px]'}
          isOpen={cartIsOpen}
          onOpenChange={isOpen => {
            handleCartOpenStateChange(isOpen);
          }}
        >
          <div
            data-testid="desktop-cart-button"
            onMouseEnter={() =>
              track('cart-button-hovered', {
                page: window.location.pathname,
                sub_category: 'navigation bar',
              })
            }
          >
            <CartButton
              hasCarts={hasCarts}
              showEz490={showEz490}
              numberOfDraftOrders={groupedOrders.length}
            />
          </div>
        </MenuTooltip>
      </div>

      <div data-testid="mobile-cart-button" className="block tablet:hidden">
        <CartButton
          menuPageClickHandler={menuPageClickHandler}
          onClick={openModal}
          showEz490={showEz490}
          hasCarts={showEz490 && hasCarts}
        />
        <Modal open={isMobileModalOpen} onClose={closeModal}>
          <>
            {showEz490 ? (
              <div className="text-2xl font-bold">
                {t('cart.ez490.title', {numberDrafts: groupedOrders.length})}
              </div>
            ) : (
              <div className="text-2xl font-bold">{t('cart.modal.title')}</div>
            )}
            <div className="w-full py-12 text-black">{content}</div>
          </>
        </Modal>
      </div>
    </>
  );
};

export default Cart;
