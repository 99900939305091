import {type ParsedUrlQuery} from 'querystring';

import {type NextRouter} from 'next/router';
import queryString from 'query-string';

export const stripQueryParams = (router: NextRouter, queryParams: string[]) => {
  const query: ParsedUrlQuery = {...router.query};
  queryParams.forEach(queryParam => {
    delete query[queryParam];
  });
  return query;
};

type UpdateRouteQueryParamsOptions = {
  replace?: boolean;
  shallow?: boolean;
};

export const updateRouteQueryParams = (
  router: NextRouter,
  queryParams: ParsedUrlQuery,
  {replace = false, shallow = true}: UpdateRouteQueryParamsOptions = {},
): void => {
  const {asPath, push: routerPush, replace: routerReplace} = router;
  const stringifiedQueryString = queryString.stringify(queryParams, {
    skipNull: true,
    skipEmptyString: true,
    arrayFormat: 'bracket',
  });
  const newUrl = `${asPath.split('?', 1)[0]}${stringifiedQueryString ? `?${stringifiedQueryString}` : ''}`;
  const transitionOptions = {shallow};

  if (replace) routerReplace(newUrl, undefined, transitionOptions);
  else routerPush(newUrl, undefined, transitionOptions);
};
