import {Alert, Button, TextField} from '@ezcater/tapas';
import {Form, Formik} from 'formik';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import Counter from '@/components/Counter/Counter';
import {useGlobalFulfillmentDetailMutation} from '@/components/GlobalFulfillmentDetailProvider/GlobalFulfillmentDetailProvider';
import {type FulfillmentDetailFragment} from '@/graphql/types';
import {type TrackEvent} from '../../EventBar/types';
import {type DropdownDismissFunction} from './types';

type DetailsProps = {
  fulfillmentDetail: FulfillmentDetailFragment | null;
  onDropdownDismiss: DropdownDismissFunction;
  onMenuPage: boolean;
  onSearchPage: boolean;
  trackEvent: TrackEvent;
};

type DetailsFormValues = {
  attendees: number | null;
  eventName: string | null;
};

const Details: React.FC<DetailsProps> = ({
  fulfillmentDetail,
  onDropdownDismiss,
  onMenuPage,
  onSearchPage,
  trackEvent,
}) => {
  const {t} = useTranslation('app-bar');
  const {updateFulfillmentDetail} = useGlobalFulfillmentDetailMutation();

  const initialValues: DetailsFormValues = {
    attendees: fulfillmentDetail?.headcount || null,
    eventName: fulfillmentDetail?.eventName ?? '',
  };

  const getCTALabel = () => {
    if (onSearchPage) {
      return t('eventBar.eventDetails.cta.searchPageLabel');
    } else if (onMenuPage) {
      return t('eventBar.eventDetails.cta.menuPageLabel');
    } else {
      return t('eventBar.eventDetails.cta.accountPageLabel');
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers) => {
        const eventNameHasChanged = values?.eventName !== fulfillmentDetail?.eventName;
        const eventAttendeesHasChanged = values?.attendees !== fulfillmentDetail?.headcount;

        trackEvent('event-details-search-clicked', {
          eventName: values?.eventName,
          headcountNumber: values?.attendees,
        });

        if (!eventNameHasChanged && !eventAttendeesHasChanged) {
          return onDropdownDismiss(
            {updated: false},
            {
              eventName: values?.eventName,
              headcountNumber: values?.attendees,
            },
          );
        }

        let data: Awaited<ReturnType<typeof updateFulfillmentDetail>>;
        try {
          data = await updateFulfillmentDetail({
            eventName: values?.eventName,
            headcount: values?.attendees,
          });
        } catch (error) {
          formikHelpers.setStatus({error});
          return;
        }

        onDropdownDismiss(
          {updated: true, fulfillmentDetailId: data.fulfillmentDetail?.id},
          {
            eventName: values?.eventName,
            headcountNumber: values?.attendees,
          },
        );
      }}
    >
      {({setFieldValue, status, values, isSubmitting}) => {
        return (
          <Form className="mb-4 flex flex-col gap-4">
            {status?.error && (
              <Alert className="w-[350px]" variant="error">
                {status.error.message}
              </Alert>
            )}
            {/* --- Attendees --- */}
            <div className="flex items-center justify-between">
              <label className="font-bold" htmlFor="event-details-attendees">
                {t('eventBar.eventDetails.attendees.label')}
              </label>

              <div className="flex gap-2">
                <Counter
                  id="event-details-attendees"
                  inputValue={values.attendees}
                  onChange={newValue => setFieldValue('attendees', newValue)}
                />
              </div>
            </div>

            {/* --- Event name --- */}
            <div className="flex flex-col gap-2">
              <label htmlFor="event-details-name">
                <Trans
                  i18nKey="app-bar:eventBar.eventDetails.eventName.label"
                  components={{strong: <strong />}}
                />
              </label>

              <div className="flex gap-2">
                <TextField
                  id="event-details-name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('eventName', event.target.value)
                  }
                  type="text"
                  value={values.eventName ?? ''}
                />
              </div>
            </div>

            <Button type="submit" loading={isSubmitting}>
              {getCTALabel()}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Details;
