import {useState} from 'react';
import {useDebounce, useUpdateEffect} from 'react-use';
import {Alert, Button, Card, Icon, Radio} from '@ezcater/tapas';
import {faBuilding} from '@fortawesome/pro-regular-svg-icons';
import {Form, Formik, useField, useFormikContext} from 'formik';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import AddressSearchAutocomplete from '@/components/AddressSearchAutocomplete';
import {
  useGlobalFulfillmentDetail,
  useGlobalFulfillmentDetailMutation,
} from '@/components/GlobalFulfillmentDetailProvider/GlobalFulfillmentDetailProvider';
import {FulfillmentDetailStrategy, type GeocodedAddressResult} from '@/graphql/types';
import {useGoogleMapsGeocode} from '@/hooks/useGoogleMapsGeocode';
import useSavedAddressSearch from '@/hooks/useSavedAddressSearch';
import {
  getAddressInput,
  getFullAddress,
  gtm,
  MicroConversionEnum,
  trackMicroConversion,
} from '@/utils';
import {deliveryAddressEnteredPayload} from '@/utils/googleAnalyticsEvents';
import {type Address as SavedAddressResult, GeocodedAddress} from '@/utils/googleAutocomplete';
import {type TrackEvent} from '../../EventBar/types';
import {type DropdownDismissFunction} from './types';

type LocationFormValues = {
  addressString: string;
  eventOrderType: FulfillmentDetailStrategy;
  geocodedAddressResult: GeocodedAddressResult | null;
  googlePlace: google.maps.places.AutocompletePrediction | null;
  savedAddress: SavedAddressResult | null;
};

type LocationProps = {
  fallbackAddress?: string;
  onDropdownDismiss: DropdownDismissFunction;
  onMenuPage?: boolean;
  trackEvent: TrackEvent;
};

const AddressUpdateListener: React.FC<{
  setMultipleAddresses: React.Dispatch<React.SetStateAction<GeocodedAddress[] | null>>;
  setShowIncompleteAddressWarning: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({setMultipleAddresses, setShowIncompleteAddressWarning}) => {
  const [addressString] = useField('addressString');
  const [savedAddress] = useField('savedAddress');
  const {setFieldValue} = useFormikContext<LocationFormValues>();

  // When the address input changes
  useUpdateEffect(() => {
    // Reset multiple addresses and alerts
    setMultipleAddresses(null);
    setShowIncompleteAddressWarning(false);

    // And it's different from any selected saved address, reset the saved address
    if (savedAddress?.value && savedAddress.value.secondaryText !== addressString?.value) {
      setFieldValue('savedAddress', null);
    }
  }, [addressString.value]);

  return null;
};

const Location: React.FC<LocationProps> = ({
  fallbackAddress,
  onDropdownDismiss,
  onMenuPage = false,
  trackEvent,
}) => {
  const {t} = useTranslation('app-bar');
  const {fulfillmentDetail} = useGlobalFulfillmentDetail();
  const {address, strategy} = fulfillmentDetail || {};
  const {updateFulfillmentDetail} = useGlobalFulfillmentDetailMutation();
  const {fetchGeocodedAddress} = useGoogleMapsGeocode();
  const [multipleAddresses, setMultipleAddresses] = useState<GeocodedAddress[] | null>(null);
  const hasMultipleAddresses = Boolean(multipleAddresses && multipleAddresses.length > 0);
  const [showIncompleteAddressWarning, setShowIncompleteAddressWarning] = useState(false);
  const cta = onMenuPage
    ? t('eventBar.eventLocation.cta.menu')
    : t('eventBar.eventLocation.cta.search');

  const strategyOptions = Object.keys(
    FulfillmentDetailStrategy,
  ) as (keyof typeof FulfillmentDetailStrategy)[];

  const {addresses: savedAddresses} = useSavedAddressSearch({
    searchTerm: ' ',
    limit: 2,
  });

  // Debounce to avoid double firing the "shown saved addresses" event
  // when the location dropdown is opened with saved addresses already loaded.
  useDebounce(
    () => {
      if (savedAddresses?.length > 0) {
        trackEvent('shown saved addresses', {}, {url: window.location.href});
      }
    },
    250,
    [savedAddresses],
  );

  const initialValues = {
    addressString: address?.fullAddress || fallbackAddress || '',
    eventOrderType: strategy || FulfillmentDetailStrategy.Delivery,
    geocodedAddressResult: null,
    googlePlace: null,
    savedAddress: null,
  } satisfies LocationFormValues;

  return (
    <Formik<LocationFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers) => {
        trackEvent('location-details-search-clicked');

        setShowIncompleteAddressWarning(false);

        // If the address is empty, show a warning
        if (values?.addressString === '') {
          setShowIncompleteAddressWarning(true);
          return;
        }

        // Log the event to google tag manager
        gtm.dataLayer(deliveryAddressEnteredPayload({clickText: cta}));

        // Get address input
        const hasChangedAddress = values?.addressString !== address?.fullAddress;
        const addressInput = hasChangedAddress
          ? await getAddressInput({fetchGeocodedAddress, values})
          : undefined;

        // Set multiple addresses and show incomplete address warning if needed
        const [multipleAddresses, updateInputAddress] = Array.isArray(addressInput)
          ? [addressInput, null]
          : [null, addressInput];
        let showIncompleteAddressWarning = false;
        if (multipleAddresses) setMultipleAddresses(multipleAddresses);
        else if (hasChangedAddress && updateInputAddress == null) {
          setShowIncompleteAddressWarning(true);
          showIncompleteAddressWarning = true;
        }

        // Get the input to be updated - do not update if values have not changed
        const updateInput = Object.fromEntries(
          Object.entries({
            ...updateInputAddress,
            strategy: values?.eventOrderType !== strategy ? values.eventOrderType : undefined,
          }).filter(([, value]) => value !== undefined),
        );

        const okToClose =
          (!multipleAddresses || multipleAddresses.length === 0) && !showIncompleteAddressWarning;

        // No changes
        if (Object.keys(updateInput).length === 0 && okToClose) {
          onDropdownDismiss({updated: false});
          return;
        }

        // Update the fulfillment details and track any relevant events
        let data: Awaited<ReturnType<typeof updateFulfillmentDetail>> | undefined = undefined;
        if (Object.keys(updateInput).length > 0) {
          try {
            data = await updateFulfillmentDetail(updateInput);
          } catch (error) {
            formikHelpers.setStatus({error});
            return;
          }

          if (updateInputAddress) trackEvent('address-updated', updateInputAddress);
          if (updateInput?.strategy) {
            trackEvent('order-type-updated', {orderType: values?.eventOrderType});
            trackMicroConversion(MicroConversionEnum.updateOrderType);
          }
        }

        if (okToClose) {
          const savedAddressParts =
            savedAddresses && updateInputAddress?.addressId
              ? savedAddresses
                  .find(add => add.placeId === updateInputAddress.addressId)
                  ?.secondaryText.split(', ')
              : undefined;

          onDropdownDismiss(
            {updated: !!data, fulfillmentDetailId: data?.fulfillmentDetail?.id},
            {
              street:
                savedAddressParts?.[0] || updateInputAddress?.address?.street1 || address?.street,
              city: savedAddressParts?.[1] || updateInputAddress?.address?.city || address?.city,
              state:
                savedAddressParts?.[2].split(' ')?.[0] ||
                updateInputAddress?.address?.state ||
                address?.state,
              orderType: updateInput?.strategy || strategy,
            },
          );
        }
      }}
    >
      {({isSubmitting, setFieldValue, values}) => {
        return (
          <Form className="mb-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="font-bold">{t('eventBar.eventLocation.orderType.label')}</div>

              {/* --- Order type --- */}
              <div className="flex gap-4">
                {strategyOptions.map(strategy => {
                  const orderType = FulfillmentDetailStrategy[strategy];

                  return (
                    <Card
                      as="label"
                      className={twJoin(
                        'flex w-full cursor-pointer gap-2 p-3 hover:bg-peppercorn-50',
                        values.eventOrderType === orderType && 'font-bold',
                      )}
                      key={strategy}
                    >
                      <Radio
                        name="eventOrderType"
                        value={orderType}
                        checked={values.eventOrderType === orderType}
                        onChange={() => setFieldValue('eventOrderType', orderType)}
                      />
                      {t(`eventBar.eventLocation.orderType.${strategy}`)}
                    </Card>
                  );
                })}
              </div>
            </div>

            {/* --- Saved addresses --- */}
            {savedAddresses.length > 0 && (
              <div className="flex flex-col gap-2">
                <div className="font-bold">{t('eventBar.eventLocation.savedAddress.label')}</div>

                {savedAddresses.map(address => (
                  <Card
                    as="button"
                    className={twJoin(
                      'flex w-full cursor-pointer items-center gap-4 p-3 hover:bg-peppercorn-50 hover:shadow-none active:border-peppercorn-300',
                      values.savedAddress?.placeId === address.placeId &&
                        'border-ezgreen-400 ring-1 ring-ezgreen-400',
                    )}
                    key={address.placeId}
                    onClick={() => {
                      if (values.savedAddress?.placeId !== address.placeId) {
                        trackEvent(
                          'saved address selected',
                          {},
                          {
                            url: window.location.href,
                            search_keyword: address?.mainText || '',
                          },
                        );
                      }

                      setMultipleAddresses(null);
                      setShowIncompleteAddressWarning(false);
                      setFieldValue('savedAddress', address);
                      setFieldValue('addressString', address.secondaryText);
                    }}
                  >
                    <div className="bg-kiwi-50 p-3">
                      <Icon icon={faBuilding} size="medium" />
                    </div>

                    <div className="flex w-3/4 flex-col text-left">
                      <div className="font-bold">{address.mainText}</div>
                      <div className="truncate font-normal">{address.secondaryText}</div>
                    </div>
                  </Card>
                ))}
              </div>
            )}

            {/* --- Event location --- */}
            <div className="flex flex-col gap-2">
              <label className="font-bold" htmlFor="event-location-address">
                {t('eventBar.eventLocation.addressSearch.label')}
              </label>

              {showIncompleteAddressWarning && (
                <Alert variant="error">
                  {t('eventBar.eventLocation.warning.incompleteAddress')}
                </Alert>
              )}

              <AddressSearchAutocomplete
                geocodedAddressResultName="geocodedAddressResult"
                googlePlaceName="googlePlace"
                id="event-location-address"
                inputName="addressString"
                savedAddressName="savedAddress"
                shouldIncludeSavedAddresses
                trackingSubcategory="navigation bar"
              />

              <AddressUpdateListener
                setMultipleAddresses={setMultipleAddresses}
                setShowIncompleteAddressWarning={setShowIncompleteAddressWarning}
              />

              {multipleAddresses && multipleAddresses.length > 1 && (
                <div className="mt-1 flex flex-col gap-2">
                  <Alert className="leading-tight" variant="error">
                    {t('eventBar.eventLocation.warning.multipleAddresses')}
                  </Alert>

                  <div className="flex flex-col gap-1">
                    {multipleAddresses.map((multipleAddress, index) => (
                      <Button
                        key={index}
                        className="border-peppercorn-200 font-normal"
                        data-testid="multiple-address-match"
                        onClick={() => {
                          setFieldValue('geocodedAddressResult', multipleAddress);
                          setFieldValue('addressString', getFullAddress(multipleAddress));
                          setMultipleAddresses(null);
                        }}
                        variant="outlined"
                      >
                        <div className="text-center">{getFullAddress(multipleAddress)}</div>
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <Button
              className="w-full"
              disabled={hasMultipleAddresses || showIncompleteAddressWarning}
              loading={isSubmitting}
              type="submit"
            >
              {cta}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Location;
