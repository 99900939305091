import {useEffect} from 'react';
import {Button, Chip} from '@ezcater/tapas';
import useTranslation from 'next-translate/useTranslation';
import {twMerge} from 'tailwind-merge';

import {useFulfillmentDetailState} from '@/components/FulfillmentDetailStateProvider/FulfillmentDetailStateProvider';
import Popup from '@/components/Popup';
import useTracking from '@/hooks/useTracking';

type CoachmarkProps = React.ComponentProps<typeof Button> & {
  clickHandler: any;
  defaultOpen?: boolean;
  isOpen: boolean;
};

const Coachmark: React.FC<CoachmarkProps> = ({clickHandler, isOpen}) => {
  const {t} = useTranslation('app-bar');
  const {track} = useTracking();
  const {setCoachmarkSeen} = useFulfillmentDetailState();

  useEffect(() => {
    track('event-bar-coachmark-seen', {
      page: window.location.pathname,
      sub_category: 'navigation bar',
    });

    setCoachmarkSeen();
  }, [setCoachmarkSeen, track]);

  return (
    <Popup
      isControlled
      offset={34}
      onPopupToggle={clickHandler}
      open={isOpen}
      slotProps={{
        root: {className: twMerge('[&_#popup]:!left-[74px]')},
        popupCard: {className: twMerge('w-[280px] p-4')},
      }}
      trigger={<span></span>}
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <Chip className="bg-blueberry-400 text-white" size="small">
            {t('eventBar.coachmark.new')}
          </Chip>
          <p className="font-semibold">{t('eventBar.coachmark.title')}</p>
        </div>
        <p>{t('eventBar.coachmark.text')}</p>
        <Button variant="outlined" className="self-end" onClick={clickHandler}>
          {t('eventBar.coachmark.button')}
        </Button>
      </div>
    </Popup>
  );
};

export default Coachmark;
