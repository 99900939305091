import {useMemo, useRef, useState} from 'react';
import {useDebounce} from 'react-use';
import {Button, Icon, tapasClassNames, TextField} from '@ezcater/tapas';
import {faArrowTrendUp, faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {ClickAwayListener} from '@mui/base';
import * as motion from 'motion/react-m';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import {useFulfillmentDetailState} from '@/components/FulfillmentDetailStateProvider/FulfillmentDetailStateProvider';
import Experiments from '@/Experiments';
import FeatureFlags from '@/FeatureFlags';
import {TypeaheadDocType, useTypeaheadSearchQuery} from '@/graphql/types';
import useExperiment from '@/hooks/useExperiment';
import useEz486RemoveWFHSearchBarAndRecentAddresses from '@/hooks/useEz486RemoveWFHSearchBarAndRecentAddresses';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIdentity from '@/hooks/useIdentity';
import useMediaQuery from '@/hooks/useMediaQuery';
import useTracking from '@/hooks/useTracking';
import {useConsumerCart, useDerivedMenuData} from '@/pageComponents/catering-menu/hooks';
import {FiltersUpdateSource} from '@/pageComponents/search-order-id/Filters/utils/types';
import useFilters from '@/pageComponents/search-order-id/Filters/utils/useFilters';
import {fulfillmentDetailAddressInputToStartNewSearchAddressParams} from '@/utils/geocodedAddressResult';
import SearchLink from './SearchLink';
import {PartialTypeaheadResult, SearchBarProps} from './types';
import {cuisineLinks, getExplorationLinks, getTrackingProperties} from './utils';

const MIN_CHARS = 1;

const SearchBar: React.FC<SearchBarProps> = ({isAdmin = false, isOpen, setIsOpen}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  // The shouldTrackRef prevents the useDebounce from firing again after a
  // suggestion is clicked. Closing the search bar causes an extra render.
  const shouldTrackRef = useRef(true);
  const useShortPlaceholder = useMediaQuery('(max-width: 1439px)') || isAdmin;
  const {t} = useTranslation('app-bar');
  const showGroupOrderingFilter = useFeatureFlag(FeatureFlags.GroupOrderingSearchFilter);
  const {filters, updateFilters} = useFilters();
  const [workingValue, setWorkingValue] = useState(filters.keyword);
  const {fulfillmentDetail, fallbackAddress} = useFulfillmentDetailState();
  const {track} = useTracking();
  const consumerCart = useConsumerCart();
  const {caterer} = useDerivedMenuData();
  const {data: identityData} = useIdentity();
  const {trackExposure: trackez489Exposure} = useExperiment(Experiments.TypeaheadSearch);

  const coords = {
    latitude: fulfillmentDetail?.address?.latitude || fallbackAddress?.latitude || 0,
    longitude: fulfillmentDetail?.address?.longitude || fallbackAddress?.longitude || 0,
  };

  const {data: typeaheadData} = useTypeaheadSearchQuery({
    variables: {
      isPreciseAddress: !!fulfillmentDetail?.address.street,
      latitude: coords.latitude,
      longitude: coords.longitude,
      term: workingValue,
    },
    skip: !coords.latitude || !coords.longitude || workingValue.length < MIN_CHARS,
  });

  const sortedResults = typeaheadData?.typeaheadSearch.reduce<PartialTypeaheadResult[][]>(
    (acc, result) => {
      if (result.type === TypeaheadDocType.Caterer) {
        acc[0].push(result);
      }
      if (result.type === TypeaheadDocType.Cuisine) {
        acc[1].push(result);
      }
      if (result.type === TypeaheadDocType.Diversity) {
        acc[2].push(result);
      } else {
        return acc;
      }
      return acc;
    },
    [[], [], []],
  );
  const flattenedSortedResults = sortedResults?.flat();

  const [caterers, cuisines, diversity] = sortedResults || [[], [], []];
  const {id: catererId = null} = caterer ?? {};

  const addressParams = useMemo(() => {
    return fallbackAddress
      ? fulfillmentDetailAddressInputToStartNewSearchAddressParams(fallbackAddress)
      : {};
  }, [fallbackAddress]);

  const trackingProperties = getTrackingProperties({consumerCart, fulfillmentDetail, identityData});

  const handleSearch = (iconClicked = false, event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    shouldTrackRef.current = false;
    setIsOpen(false);
    searchRef.current?.blur();
    updateFilters({keyword: workingValue}, FiltersUpdateSource.Keyword, false);

    if (iconClicked) {
      track('search-icon-clicked', {
        caterer_id: catererId,
        misc_json: JSON.stringify({...trackingProperties}),
        page: window.location.pathname,
        sub_category: 'navigation bar',
      });
    } else {
      track('search-button-clicked', {
        caterer_id: catererId,
        misc_json: JSON.stringify({...trackingProperties}),
        page: window.location.pathname,
        sub_category: 'navigation bar',
      });
    }
  };

  const suggestions = useMemo(() => {
    const trackClick = (suggestion: PartialTypeaheadResult) => {
      shouldTrackRef.current = false;
      const rank = flattenedSortedResults
        ? flattenedSortedResults.findIndex(x => x === suggestion) + 1
        : null;

      track('clicked-typeahead-suggestion', {
        caterer_id: catererId,
        misc_json: JSON.stringify({
          clickedCatererPath: suggestion.type === TypeaheadDocType.Caterer ? suggestion.id : null,
          clickedSuggestionRank: rank,
          clickedValue: suggestion.display,
          keyword: workingValue,
          suggestionCount: flattenedSortedResults?.length,
          type: suggestion.type,
        }),
        page: window.location.pathname,
        sub_category: 'navigation bar',
      });

      if (suggestion.type !== TypeaheadDocType.Caterer) {
        track('filters-updated', {
          misc_json: JSON.stringify({
            filters: null,
            key: 'typeahead_suggestion',
            search_id: null,
          }),
          page: window.location.pathname,
        });
      }
    };

    return workingValue.length < MIN_CHARS
      ? []
      : [
          {
            group: 'KEYWORD',
            listItem: (
              <li key="keyword">
                {t('search.keyword.suggestions.keywordSearch', {keyword: workingValue})}
              </li>
            ),
          },
          ...caterers.map((sugg: PartialTypeaheadResult) => ({
            group: TypeaheadDocType.Caterer,
            listItem: (
              <SearchLink
                addressParams={addressParams}
                fulfillmentDetail={fulfillmentDetail}
                onClick={() => {
                  setIsOpen(false);
                  trackClick(sugg);
                }}
                suggestion={sugg}
              />
            ),
          })),
          ...cuisines.map((sugg: PartialTypeaheadResult) => ({
            group: TypeaheadDocType.Cuisine,
            listItem: (
              <SearchLink
                addressParams={addressParams}
                fulfillmentDetail={fulfillmentDetail}
                onClick={() => {
                  setIsOpen(false);
                  trackClick(sugg);
                }}
                suggestion={sugg}
              />
            ),
          })),
          ...diversity.map((sugg: PartialTypeaheadResult) => ({
            group: TypeaheadDocType.Diversity,
            listItem: (
              <SearchLink
                addressParams={addressParams}
                fulfillmentDetail={fulfillmentDetail}
                onClick={() => {
                  setIsOpen(false);
                  trackClick(sugg);
                }}
                suggestion={sugg}
              />
            ),
          })),
        ];
  }, [
    workingValue,
    t,
    caterers,
    cuisines,
    diversity,
    track,
    catererId,
    flattenedSortedResults,
    addressParams,
    fulfillmentDetail,
    setIsOpen,
  ]);

  const explorationLinks = getExplorationLinks(showGroupOrderingFilter);

  const handleInputTracking = () => {
    if (!isOpen) {
      // treatment
      trackez489Exposure();
      track('search-bar-clicked', {
        caterer_id: catererId,
        misc_json: JSON.stringify({
          ...trackingProperties,
          defaultSuggestions: suggestions?.length
            ? []
            : [
                ...cuisineLinks.map(({display, category}) => ({display, category})),
                ...explorationLinks.map(({display, category}) => ({display, category})),
              ],
          suggestions: cuisines.map(sugg => ({value: sugg.id, type: sugg.type})),
        }),
        page: window.location.pathname,
        sub_category: 'navigation bar',
      });
    }
    setIsOpen(true);
    shouldTrackRef.current = true;
  };

  useDebounce(
    () => {
      if (flattenedSortedResults?.length && !!shouldTrackRef.current) {
        track('viewed-typeahead-suggestion', {
          caterer_id: catererId,
          misc_json: JSON.stringify({
            catererSuggestions: caterers.map(x => x.id),
            cuisineSuggestions: cuisines.map(x => x.id),
            diversitySuggestions: diversity.map(x => x.id),
            keyword: workingValue,
          }),
          page: window.location.pathname,
          sub_category: 'navigation bar',
        });
      }
    },
    300,
    [flattenedSortedResults],
  );

  const trackRecommendedSearch = (label: string, category: string) => {
    track('recommended-search-clicked', {
      caterer_id: catererId,
      misc_json: JSON.stringify({
        category: category,
        clickedValue: label,
        ...trackingProperties,
      }),
      page: window.location.pathname,
      sub_category: 'navigation bar',
    });

    track('filters-updated', {
      page: window.location.pathname,
      misc_json: JSON.stringify({
        filters: null,
        key: 'recommended_search',
        search_id: null,
      }),
    });
  };

  // ez486
  const {removeWFHSearchBarAndRecentAddresses} = useEz486RemoveWFHSearchBarAndRecentAddresses();

  const handleHoverTracking = () => {
    track('search-icon-hovered', {
      misc_json: JSON.stringify({...trackingProperties}),
      page: window.location.pathname,
      sub_category: 'navigation bar',
    });
  };
  // end ez486

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false);
        shouldTrackRef.current = false;
      }}
    >
      <motion.div
        className={twJoin(
          'hidden w-auto min-w-[145px] max-w-[800px] bg-white desktop1279:block',
          tapasClassNames.input.border,
          !isOpen && tapasClassNames.focusWithin,
          !isAdmin && 'desktop1440:min-w-[330px]',
          isOpen
            ? 'fixed left-44 top-[18px] z-1300 w-full rounded-xl p-4 pt-2 shadow-outer'
            : '!rounded-full',
        )}
        data-testid="search-bar-v2"
        initial={false}
        key="SearchBar"
        layout
        layoutDependency={isOpen}
        ref={searchRef}
        transition={{layout: {duration: isOpen ? 0.15 : 0}}}
      >
        <TextField
          autoFocus={isOpen}
          className={twJoin(
            'w-full rounded-full border-0 focus-within:ring-0 [&>input]:truncate',
            removeWFHSearchBarAndRecentAddresses && '[&_div:first-of-type]:right-1.5',
          )}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setWorkingValue(event.target.value)
          }
          onFocus={handleInputTracking}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              setIsOpen(!isOpen);
              handleSearch();
            }
          }}
          placeholder={t(`search.input.placeholder.${useShortPlaceholder ? 'short' : 'long'}`)}
          prefix={
            !removeWFHSearchBarAndRecentAddresses && (
              <Icon
                className={isOpen ? 'text-ezgreen-300' : 'text-peppercorn-800'}
                icon={faMagnifyingGlass}
                size="xsmall"
              />
            )
          }
          style={{paddingRight: 0}}
          suffix={
            <>
              {removeWFHSearchBarAndRecentAddresses && !isOpen && (
                <Button
                  aria-label={t('search.input.label')}
                  className="rounded-full p-2"
                  data-testid="search-bar-search-icon"
                  onClick={event => handleSearch(true, event)}
                  onMouseEnter={handleHoverTracking}
                >
                  <Icon className="text-white" icon={faMagnifyingGlass} size="xsmall" />
                </Button>
              )}
              {isOpen && (
                <Button className="-mr-4 px-8" onClick={event => handleSearch(false, event)}>
                  {t('search.input.label')}
                </Button>
              )}
            </>
          }
          value={workingValue}
        />

        {/* Defaults */}
        {isOpen && !suggestions?.length && (
          <div className="mt-2 flex w-[inherit] justify-between gap-4 rounded-none border-t !border-peppercorn-100 bg-white px-4 py-6">
            <div className="flex flex-1 flex-col gap-2">
              <>
                <div className="flex gap-2">
                  <Icon className="text-ezgreen-300" icon={faArrowTrendUp} size="xsmall" />
                  <div className="text-xs font-bold uppercase tracking-wide text-peppercorn-300">
                    {t('search.content.popularSearches.label')}
                  </div>
                </div>

                <ul>
                  {cuisineLinks.map(sugg => (
                    <SearchLink
                      addressParams={addressParams}
                      fulfillmentDetail={fulfillmentDetail}
                      isDefault
                      key={sugg.display}
                      onClick={() => trackRecommendedSearch(sugg.display, sugg.category)}
                      suggestion={sugg}
                    />
                  ))}
                </ul>
              </>
            </div>

            <div className="flex flex-1 flex-col gap-2">
              <div className="text-xs font-bold uppercase tracking-wide text-peppercorn-300">
                {t('search.content.exploration.label')}
              </div>
              <ul>
                {explorationLinks
                  .filter(({featureFlag}) => !!featureFlag)
                  .map(sugg => (
                    <SearchLink
                      addressParams={addressParams}
                      fulfillmentDetail={fulfillmentDetail}
                      isDefault
                      key={sugg.display}
                      onClick={() => trackRecommendedSearch(sugg.display, sugg.category)}
                      suggestion={sugg}
                    />
                  ))}
              </ul>
            </div>

            <div className="flex-1"></div>
          </div>
        )}

        {/* With search results */}
        {isOpen && !!suggestions?.length && (
          <div className="mt-2 w-[inherit] justify-between gap-2 rounded-none border-t !border-peppercorn-100 bg-white px-4 pb-4 pt-6">
            <ul className="flex flex-col">{suggestions.map(sugg => sugg.listItem)}</ul>
          </div>
        )}
      </motion.div>
    </ClickAwayListener>
  );
};

export default SearchBar;
