import {Link} from '@ezcater/tapas';
import Head from 'next/head';
import Image from 'next/image';
import NextLink from 'next/link';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import ezcaterLogo from '@/assets/icons/ezcater-logo.svg';
import {useGlobalFulfillmentDetail} from '@/components/GlobalFulfillmentDetailProvider/GlobalFulfillmentDetailProvider';
import {SEARCH_NEW_PATH} from '@/paths';
import {compilePath} from '@/utils';
import {FulfillmentDetailResultToStartNewSearchAddressParams} from '@/utils/fulfillmentDetailResult';
import useNavLinkTracking from '../hooks/useNavlinkTracking';

const Logo: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');

  const {fulfillmentDetail} = useGlobalFulfillmentDetail();
  const fulfillmentDetailAddress = fulfillmentDetail;
  const {trackLink} = useNavLinkTracking();
  const router = useRouter();
  const currentPath = router.pathname;

  const address = fulfillmentDetailAddress
    ? FulfillmentDetailResultToStartNewSearchAddressParams(fulfillmentDetailAddress)
    : {};

  const trackLogoClick = () => {
    trackLink(currentPath, 'logo');
  };
  const path = compilePath(
    SEARCH_NEW_PATH,
    {},
    {
      ...address,
    },
    {skipEmptyString: true, skipNull: true},
  );

  return (
    <>
      <Head>
        <link rel="preload" href={ezcaterLogo.src} as="image" />
      </Head>

      <Link
        as={NextLink}
        href={path}
        className="w-24 shrink-0 desktop:w-32"
        onClick={trackLogoClick}
      >
        <Image alt={t('logo.label')} src={ezcaterLogo} priority />
      </Link>
    </>
  );
};

export default Logo;
