import {useState} from 'react';
import {Modal} from '@ezcater/tapas';
import useTranslation from 'next-translate/useTranslation';

import useNavLinkTracking from '../hooks/useNavlinkTracking';
import MenuTooltip from '../Menus/MenuTooltip';
import DirectConnectNotification from './DirectConnectNotification';
import NotificationBadge from './NotificationBadge';
import NotificationsButton from './NotificationButton';
import NotificationsFeedback from './NotificationsFeedback';

export type NotificationType = {
  id: string | null;
  brandName: string | null;
  changeType: string | null;
  isUnread: boolean;
  message: string | null;
  messageType: string | null;
  orderUuid: string;
};

type NotificationsProps = {
  isAdmin?: boolean;
  notifications?: NotificationType[] | undefined;
};

const Notifications: React.FC<NotificationsProps> = ({isAdmin, notifications}) => {
  const {t} = useTranslation('app-bar');
  const unreadNotificationCount = notifications
    ? notifications?.filter(notification => notification.isUnread).length
    : 0;
  const {trackLink} = useNavLinkTracking();
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const dismissMobileModal = () => setIsMobileModalOpen(false);
  const openMobileModal = () => setIsMobileModalOpen(true);

  if (notifications && notifications?.length > 0) {
    return (
      <>
        <div className="hidden tablet:block">
          <MenuTooltip
            arrowClasses="right-0"
            className="mr-6 w-[350px] p-0"
            content={
              <>
                <div className="border-b border-b-peppercorn-100 p-4 text-xl font-bold text-peppercorn-800">
                  {t('notifications.title')}
                </div>
                {notifications.map(
                  ({id, orderUuid, isUnread, brandName, messageType, changeType, message}) => (
                    <DirectConnectNotification
                      key={id}
                      brandName={brandName}
                      changeType={changeType}
                      isUnread={isUnread}
                      message={message}
                      messageType={messageType}
                      orderUuid={orderUuid}
                      trackLink={trackLink}
                    />
                  ),
                )}
              </>
            }
            placement="bottom-start"
          >
            <div className="relative flex items-center justify-start gap-1">
              <NotificationsButton
                isAdmin={isAdmin}
                unreadNotificationCount={unreadNotificationCount}
              />
              {unreadNotificationCount > 0 && (
                <NotificationBadge unreadNotificationCount={unreadNotificationCount} />
              )}
            </div>
          </MenuTooltip>
        </div>
        <div className="relative flex items-center justify-start gap-1 tablet:hidden">
          <NotificationsButton
            isAdmin={isAdmin}
            unreadNotificationCount={unreadNotificationCount}
            onClick={openMobileModal}
          />
          {unreadNotificationCount > 0 && (
            <NotificationBadge unreadNotificationCount={unreadNotificationCount} />
          )}
        </div>
        <Modal
          slotProps={{
            content: {className: 'px-0 block'},
            root: {className: 'tablet:hidden'},
          }}
          open={isMobileModalOpen}
          onClose={dismissMobileModal}
        >
          <>
            <div className="border-b border-b-peppercorn-100 p-4 text-2xl font-bold">
              {t('notifications.title')}
            </div>
            {notifications.map(
              ({id, orderUuid, isUnread, brandName, messageType, changeType, message}) => (
                <DirectConnectNotification
                  key={id}
                  brandName={brandName}
                  changeType={changeType}
                  isUnread={isUnread}
                  message={message}
                  messageType={messageType}
                  orderUuid={orderUuid}
                  trackLink={trackLink}
                />
              ),
            )}
          </>
        </Modal>
      </>
    );
  }
  return <NotificationsFeedback />;
};

export default Notifications;
