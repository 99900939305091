import {faCalendar, faLocationDot, faUsers} from '@fortawesome/pro-light-svg-icons';
import {ClickAwayListener} from '@mui/base';
import {parseISO} from 'date-fns';
import {format} from 'date-fns/format';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import {EventBarTrackingEvent} from '@/components/EventBar/types';
import {useFulfillmentDetailState} from '@/components/FulfillmentDetailStateProvider/FulfillmentDetailStateProvider';
import useEz486RemoveWFHSearchBarAndRecentAddresses from '@/hooks/useEz486RemoveWFHSearchBarAndRecentAddresses';
import useIdentity from '@/hooks/useIdentity';
import useTracking from '@/hooks/useTracking';
import {useConsumerCart, useDerivedMenuData} from '@/pageComponents/catering-menu/hooks';
import {SEARCH_NEW_PATH, SEARCH_ORDER_ID_PATH} from '@/paths';
import {compilePath} from '@/utils';
import {fulfillmentDetailAddressInputToString} from '@/utils/geocodedAddressResult';
import {stripQueryParams, updateRouteQueryParams} from '@/utils/routing';
import Coachmark from './Coachmark';
import DateTime from './DateTime';
import Details from './Details';
import EventBarPopup from './EventBarPopup';
import Location from './Location';
import {type DropdownDismissFunction} from './types';

type EventBarProps = {
  onMenuPage?: boolean;
  onSearchPage?: boolean;
};

const EventBar: React.FC<EventBarProps> = ({onMenuPage = false, onSearchPage = false}) => {
  const {t} = useTranslation('app-bar');
  const {
    fulfillmentDetailClickHandler,
    fulfillmentDetail,
    fulfillmentDetailState,
    FulfillmentDetailStates,
    fallbackAddress,
    dismissCoachmark,
    setCoachmarkDismissed,
  } = useFulfillmentDetailState();
  const {
    address,
    id: fulfillmentDetailId,
    eventAt,
    eventLocalTime,
    eventName,
    eventOn,
    headcount,
    strategy,
  } = fulfillmentDetail || {};
  const isLocationOpen = fulfillmentDetailState === FulfillmentDetailStates.LOCATION;
  const isDateTimeOpen = fulfillmentDetailState === FulfillmentDetailStates.DATETIME;
  const isDetailsOpen = fulfillmentDetailState === FulfillmentDetailStates.DETAILS;
  const isCoachmarkOpen = fulfillmentDetailState === FulfillmentDetailStates.COACHMARK; // ez486
  const {caterer} = useDerivedMenuData();
  const {data: identityData} = useIdentity();
  const tracker = useTracking();
  const consumerCart = useConsumerCart();
  const corpAccountId = identityData?.me?.consumerAccount?.corporateAccount?.id ?? null;
  const fallbackAddressString = fallbackAddress
    ? fulfillmentDetailAddressInputToString(fallbackAddress)
    : '';
  const {id: catererId} = caterer ?? {};
  const cartId = consumerCart?.id ?? null;
  const router = useRouter();

  const trackEvent = (
    trackingEventName: EventBarTrackingEvent,
    misc: Record<string, unknown> = {},
    overrides: Record<string, unknown> = {},
  ) => {
    tracker.track(trackingEventName, {
      page: window.location.pathname,
      sub_category: 'navigation bar',
      caterer_id: catererId || null,
      misc_json: JSON.stringify({
        consumer_cart_id: cartId,
        fulfillment_detail_id: fulfillmentDetailId,
        street: address?.street,
        city: address?.city,
        state: address?.state,
        corpAccountId: corpAccountId || null,
        eventName,
        orderType: strategy,
        eventDate: eventOn,
        eventTime: eventLocalTime,
        headcountNumber: headcount,
        ...misc,
      }),
      ...overrides,
    });
  };

  // ez486
  const {removeWFHSearchBarAndRecentAddresses} = useEz486RemoveWFHSearchBarAndRecentAddresses();

  const handleDismissCoachmark = () => {
    if (removeWFHSearchBarAndRecentAddresses && isCoachmarkOpen) {
      setCoachmarkDismissed();
      tracker.trackClick('event-bar-coachmark-dismissed-by-fulfillment-detail-click');
    }
  };
  // end ez486

  const dismissActiveDropdown = async (
    trackingMisc: Record<string, unknown> = {},
    event: Event | null = null,
  ) => {
    if (isLocationOpen) trackEvent('location-details-dismissed', trackingMisc);
    else if (isDateTimeOpen) trackEvent('date-time-dismissed', trackingMisc);
    else if (isDetailsOpen) trackEvent('event-details-dismissed', trackingMisc);

    // if a user clicks this text/button in the cart, we don't want to dismiss all dropdowns
    // it is a button that opens the details dropdown with the guest count
    const buttonText = (event?.target as HTMLElement)?.innerText;
    if (buttonText === 'Add your guest count for cost per person') return;

    if (!removeWFHSearchBarAndRecentAddresses || !isCoachmarkOpen) {
      fulfillmentDetailClickHandler(FulfillmentDetailStates.NONE);
    }
  };

  const onDropdownDismiss: DropdownDismissFunction = async (formStatus, trackingMisc = {}) => {
    dismissActiveDropdown(trackingMisc);

    if (onSearchPage) {
      if (formStatus.updated) updateRouteQueryParams(router, stripQueryParams(router, ['page']));
    } else if (!onMenuPage) {
      if (formStatus.fulfillmentDetailId || fulfillmentDetailId)
        router.push(
          compilePath(SEARCH_ORDER_ID_PATH, {
            orderId: formStatus.fulfillmentDetailId || fulfillmentDetailId,
          }),
        );
      else router.push(compilePath(SEARCH_NEW_PATH));
    }
  };

  return (
    // TODO: when we remove ClickAwayListener we need to make sure that the "add your guest count"
    // button in the cart still works, see lines 113 - 116 above
    <ClickAwayListener onClickAway={e => dismissActiveDropdown(undefined, e)}>
      <div className="flex items-center rounded-lg">
        <EventBarPopup
          className="rounded-l-full"
          clickHandler={() => {
            dismissActiveDropdown();
            handleDismissCoachmark();
            if (!isLocationOpen) {
              fulfillmentDetailClickHandler(FulfillmentDetailStates.LOCATION);
              trackEvent('location-details-clicked');
            }
          }}
          isOpen={isLocationOpen}
          icon={faLocationDot}
          label={address?.fullAddress || fallbackAddressString || t('eventBar.eventLocation.label')}
          data-testid="location-dropdown"
        >
          <Location
            fallbackAddress={fallbackAddressString}
            onDropdownDismiss={onDropdownDismiss}
            onMenuPage={onMenuPage}
            trackEvent={trackEvent}
          />
        </EventBarPopup>

        {removeWFHSearchBarAndRecentAddresses && !dismissCoachmark && (
          <Coachmark
            clickHandler={() => {
              fulfillmentDetailClickHandler(FulfillmentDetailStates.NONE);
              handleDismissCoachmark();
              tracker.trackClick('event-bar-coachmark-dismiss-button');
            }}
            isOpen={isCoachmarkOpen}
          ></Coachmark>
        )}

        <EventBarPopup
          className="-mx-px rounded-none border-x-0"
          clickHandler={() => {
            dismissActiveDropdown();
            handleDismissCoachmark();
            if (!isDateTimeOpen) {
              fulfillmentDetailClickHandler(FulfillmentDetailStates.DATETIME);
              trackEvent('date-time-clicked');
            }
          }}
          icon={faCalendar}
          isOpen={isDateTimeOpen}
          label={
            eventAt
              ? format(eventAt, 'E LLL do')
              : eventOn
                ? format(parseISO(eventOn), 'EEE, M/d')
                : t('eventBar.eventDate.label')
          }
          popupClasses="w-auto min-w-0"
        >
          <DateTime
            onDropdownDismiss={onDropdownDismiss}
            onMenuPage={onMenuPage}
            trackEvent={trackEvent}
          />
        </EventBarPopup>

        <EventBarPopup
          className="rounded-r-full"
          clickHandler={() => {
            dismissActiveDropdown();
            handleDismissCoachmark();
            if (!isDetailsOpen) {
              trackEvent('event-details-clicked');
              fulfillmentDetailClickHandler(FulfillmentDetailStates.DETAILS);
            }
          }}
          isOpen={isDetailsOpen}
          icon={faUsers}
          label={t('eventBar.eventDetails.label')}
        >
          <Details
            fulfillmentDetail={fulfillmentDetail}
            onDropdownDismiss={onDropdownDismiss}
            onMenuPage={onMenuPage}
            onSearchPage={onSearchPage}
            trackEvent={trackEvent}
          />
        </EventBarPopup>
      </div>
    </ClickAwayListener>
  );
};

export default EventBar;
