import {FC, useState} from 'react';
import {Button, Icon, Modal, Typography} from '@ezcater/tapas';
import {faTrashCan} from '@fortawesome/pro-light-svg-icons';
import useTranslation from 'next-translate/useTranslation';

import useTracking from '@/hooks/useTracking';
import {OpenOrdersDocument, useConsumerCartDeleteMutation} from '../../../graphql/types';
import {Order} from './Cart';

type DeleteCartButtonProps = {
  orderId: Order['id'];
  setKeepCartOpen: (keepCartOpen: boolean) => void;
};

const DeleteCartButton: FC<DeleteCartButtonProps> = ({orderId, setKeepCartOpen}) => {
  const {t} = useTranslation('app-bar');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const {trackClick} = useTracking();

  const [deleteCart] = useConsumerCartDeleteMutation({
    variables: {consumerCartDeleteId: orderId},
    refetchQueries: [OpenOrdersDocument],
  });

  const openDeleteCartModal = () => {
    trackClick('draft-order-delete-order-button', {
      misc_json: JSON.stringify({order_id: orderId}),
    });
    setKeepCartOpen(true);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDeleteClick = () => {
    trackClick('draft-order-delete-cart-modal-confirm', {
      misc_json: JSON.stringify({order_id: orderId}),
    });
    deleteCart();
    setKeepCartOpen(false);
    setIsDeleteModalOpen(false);
  };

  const handleCloseConfirmModal = () => {
    trackClick('draft-order-delete-cart-modal-closed', {
      misc_json: JSON.stringify({order_id: orderId}),
    });
    setKeepCartOpen(false);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Button
        destructive
        variant="outlined"
        className="px-3 hover:bg-guava-400 hover:text-white"
        onClick={openDeleteCartModal}
        aria-label="delete-draft-cart"
      >
        <Icon className="no-underline" icon={faTrashCan} size="small" />
      </Button>
      <Modal
        open={isDeleteModalOpen}
        onClose={handleCloseConfirmModal}
        aria-labelledby="confirm-delete-draft-title"
        aria-describedby="confirm-delete-draft-body"
      >
        <div>
          <Typography id="confirm-delete-draft-title" variant="heading-sm">
            {t('cart.ez490.draftDeleteModalHeading')}
          </Typography>
          <div id="confirm-delete-draft-body" className="my-6">
            {t('cart.ez490.draftDeleteModalBody')}
          </div>
          <div className="flex gap-4">
            <Button destructive onClick={handleConfirmDeleteClick} className="mt-6">
              {t('cart.ez490.confirmDeleteDraft')}
            </Button>
            <Button variant="outlined" onClick={handleCloseConfirmModal} className="mt-6">
              {t('cart.ez490.cancelDeleteDraft')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteCartButton;
