import {Link} from '@ezcater/tapas';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import {ORDER_VIEW_PATH} from '@/paths';
import compilePath from '@/utils/compilePath';

type MaybeString = string | null;

export type DirectConnectNotificationProps = {
  brandName: MaybeString;
  changeType: MaybeString;
  isUnread: boolean;
  message: MaybeString;
  messageType: MaybeString;
  orderUuid: string;
  trackLink: (path: string, title: string) => void;
};

const DirectConnectNotification: React.FC<DirectConnectNotificationProps> = ({
  brandName,
  changeType,
  isUnread,
  message,
  messageType,
  orderUuid,
  trackLink,
}) => {
  const {t} = useTranslation('app-bar');

  return (
    <div
      className={twJoin(
        'border-b border-b-peppercorn-100 p-3 font-normal text-peppercorn-300 last:border-b-0 hover:bg-peppercorn-50 hover:text-black',
        isUnread && 'border-l-4 border-blueberry-400 font-bold hover:bg-peppercorn-100',
      )}
      data-testid={`direct-connect-notification-${orderUuid}`}
    >
      <Link
        href={compilePath(ORDER_VIEW_PATH, {orderId: orderUuid})}
        onClick={() => {
          trackLink(
            compilePath(ORDER_VIEW_PATH, {orderId: orderUuid}),
            `Notification for order ${orderUuid} and brand ${brandName}`,
          );
        }}
      >
        <div className="mb-3 text-sm font-semibold">{brandName}</div>
      </Link>
      <div className="block max-h-9 overflow-hidden text-ellipsis break-words p-[2px] text-xs leading-4">
        <span className="pr-1 font-bold">
          {messageType ? t(`notifications.messageType.${messageType}`, {changeType}) : ''}
        </span>
        {message}
      </div>
    </div>
  );
};

export default DirectConnectNotification;
