import {Modal} from '@ezcater/tapas';

import {type TrackingFunction} from '@/components/TrackingProvider';
import {CurrentUserType} from '../../CurrentUser.types';
import ContactUsDropdownMenu from '../LandingDropdownMenus/ContactUsDropdownMenu';
import LandingDropdownMenus from '../LandingDropdownMenus/LandingDropdownMenus';
import Logo2023 from '../Logo';
import MyAccountNav from './MyAccountNav';
import {NeedHelpContent, WelcomeMessage} from './ProfileMenuModal.styles';

type ProfileMenuModalProps = {
  isOpen: boolean;
  onDismiss?: () => void;
  currentUser: CurrentUserType | undefined;
  supportPhoneNumber?: string;
  supportFaxNumber?: string;
  landingPage?: boolean;
  track: TrackingFunction;
  trackLink: (path: string, title: string) => void;
  pointsGivenForReview: number;
  pointsGivenForSavingCaterer: number;
  ezRewardsEligibility: {
    canAccessEzRewards: boolean;
    balanceEnabled: boolean;
    earningsEnabled: boolean;
  };
};

const ProfileMenuModal: React.FC<ProfileMenuModalProps> = ({
  isOpen,
  onDismiss,
  currentUser,
  supportPhoneNumber,
  supportFaxNumber,
  landingPage = false,
  track,
  trackLink,
  pointsGivenForReview,
  pointsGivenForSavingCaterer,
  ezRewardsEligibility,
}) => {
  const isLoggedIn = currentUser != null;

  return (
    <Modal
      open={isOpen}
      onClose={onDismiss}
      slotProps={{closeButton: {'aria-label': 'close menu'}, content: {className: 'p-0'}}}
    >
      <div className="flex flex-col divide-y">
        <div className="p-6">
          <Logo2023 trackLink={trackLink} />
        </div>

        <div className="p-6">
          <WelcomeMessage>
            {isLoggedIn
              ? `Welcome, ${currentUser.consumerAccount.firstName}`
              : 'Welcome to ezCater!'}
          </WelcomeMessage>
          {!isLoggedIn && landingPage && (
            <>
              <LandingDropdownMenus trackLink={trackLink} />
              <ContactUsDropdownMenu
                supportPhoneNumber={supportPhoneNumber}
                supportFaxNumber={supportFaxNumber}
                trackLink={trackLink}
              />
            </>
          )}
          <MyAccountNav
            track={track}
            trackLink={trackLink}
            area="mobile"
            currentUser={currentUser}
            showMobileItems
            pointsGivenForReview={pointsGivenForReview}
            pointsGivenForSavingCaterer={pointsGivenForSavingCaterer}
            ezRewardsEligibility={ezRewardsEligibility}
          />
          <NeedHelpContent>
            <div>Need help?</div>
            <a href="tel:18004881803">1-800-488-1803</a>
            <a href="mailto:support@ezcater.com">support@ezcater.com</a>
          </NeedHelpContent>
        </div>
      </div>
    </Modal>
  );
};

export default ProfileMenuModal;
