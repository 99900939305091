import {Icon, Link} from '@ezcater/tapas';
import {faLock} from '@fortawesome/pro-regular-svg-icons';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import {ADMIN_ORDER_PATH, ADMIN_PATH} from '@/paths';
import {compilePath} from '@/utils';
import useNavLinkTracking from '../hooks/useNavlinkTracking';

const Admin: React.FC<unknown> = () => {
  const {query} = useRouter();
  const cartId = query?.cartId;
  const {trackLink} = useNavLinkTracking();
  const {t} = useTranslation('app-bar');
  const adminPath = cartId ? compilePath(ADMIN_ORDER_PATH, {cartId}) : compilePath(ADMIN_PATH);

  return (
    <Link
      aria-label={t('admin.label')}
      className="rounded-lg p-2 text-peppercorn-800 hover:bg-peppercorn-100 hover:text-peppercorn-800 active:bg-peppercorn-200 active:text-peppercorn-800"
      data-testid="freedom-admin-lock"
      href={adminPath}
      onClick={() => trackLink(adminPath, 'admin-lock-icon')}
      title={t('admin.label')}
    >
      <Icon icon={faLock} size="medium" />
    </Link>
  );
};

export default Admin;
