import {format, parseISO} from 'date-fns';
import {twJoin} from 'tailwind-merge';

import useNewAppBarExperience from '@/hooks/useNewAppBarExperience';
import redirect from '@/utils/redirect';
import {type Order as OrderType} from '../../../types';
import {
  AccountName,
  CartOrderContainer,
  CartOrderDetails,
  CatererLocation,
  OrderUpdatedLast,
  Title,
} from './Order.styles';

export type OrderProps = {
  currentCatererId?: string;
  currentOrderId?: string;
  onClick: (order: OrderType) => void;
  order: OrderType;
  path: string;
  trackLink: (path: string, title: string) => void;
  accountHierarchyPresent?: boolean;
};

const humanizedOrderDate = (orderDate: string) => {
  const parsedTime = parseISO(orderDate);
  const formattedTime = format(parsedTime, 'MMMM d');
  return `Last updated ${formattedTime}`;
};

const Order: React.FC<OrderProps> = ({
  currentCatererId,
  currentOrderId,
  onClick,
  order,
  path,
  trackLink,
  accountHierarchyPresent,
}) => {
  const isCurrentOrder = currentOrderId === order.id && currentCatererId === order.caterer.id;
  const onClickLink = () => {
    if (isCurrentOrder) {
      return;
    }
    trackLink(path, `Clicked on draft order ${order.id}`);
    onClick(order);
    redirect(path);
  };

  // ez479 -- New AppBar
  const {shouldShowNewAppBar} = useNewAppBarExperience();

  const content = (
    <Title isCurrentOrder={isCurrentOrder}>
      {order.eventName ? (
        <>{order.eventName}</>
      ) : (
        <>
          {order.caterer.name}
          {isCurrentOrder && ' (current)'}
        </>
      )}
    </Title>
  );

  return (
    <CartOrderContainer isCurrentOrder={isCurrentOrder}>
      <CartOrderDetails className={twJoin(shouldShowNewAppBar && 'h-24')}>
        {isCurrentOrder ? (
          <span>{content}</span>
        ) : (
          <a href={path} onClick={onClickLink} data-testid="order-link">
            {content}
          </a>
        )}
        <CatererLocation>
          {order.eventName && <>{order.caterer.name}, </>}
          {order.caterer.address
            ? `${order.caterer.address.city}, ${order.caterer.address.state}`
            : null}
        </CatererLocation>
        {accountHierarchyPresent && <AccountName>{order.accountName}</AccountName>}
        {order.updatedAt && (
          <OrderUpdatedLast>{humanizedOrderDate(order.updatedAt)}</OrderUpdatedLast>
        )}
      </CartOrderDetails>
    </CartOrderContainer>
  );
};

export default Order;
