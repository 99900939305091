export const formatPoints = (points: number) => {
  const symbolLookup = [
    {value: 1e6, symbol: 'm'},
    {value: 1e3, symbol: 'k'},
    {value: 1, symbol: ''},
  ];

  const symbol = symbolLookup.find(({value}) => points >= value);

  return symbol
    ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
      })
        .format(symbol.value > 1 ? Math.floor((points / symbol.value) * 100) / 100 : points)
        .concat(symbol.symbol)
    : '0';
};
