import {useEffect, useState} from 'react';
import {faBars, faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import * as motion from 'motion/react-m';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import Experiments from '@/Experiments';
import useExperiment from '@/hooks/useExperiment';
import useIdentity from '@/hooks/useIdentity';
import useOrderingExperience from '@/hooks/useOrderingExperience';
import {ROOT_PATH, SEARCH_ORDER_ID_PATH} from '@/paths';
import {compilePath} from '@/utils';
import {useDirectConnectNotifications} from '../DirectConnectNotificationsProvider';
import {useGlobalFulfillmentDetail} from '../GlobalFulfillmentDetailProvider';
import LoggedOutMenu from './Menus/LoggedOutMenu';
import MenuLink from './Menus/MenuLink';
import Admin from './Admin';
import AppBarIconButton from './AppBarIconButton';
import Cart from './Cart';
import ContactUs from './ContactUs';
import EventBar from './EventBar';
import Logo from './Logo';
import {MenuModal} from './Menus';
import Notifications from './Notifications';
import SearchBar from './SearchBar';
import SearchBarV2 from './SearchBarV2';
import {SignIn, User} from './User';

type NewAppBarProps = {
  menuPageClickHandler: () => void;
  onMenuPage?: boolean;
  onSearchPage?: boolean;
};

const NewAppBar: React.FC<NewAppBarProps> = ({menuPageClickHandler, onMenuPage, onSearchPage}) => {
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const {isAdmin, isLoggedIn} = useIdentity();
  const router = useRouter();
  const isLoggedOutLandingPage = !isLoggedIn && router.pathname === ROOT_PATH;
  const {fulfillmentDetail} = useGlobalFulfillmentDetail();
  const {t} = useTranslation('app-bar');
  const notifications = useDirectConnectNotifications();
  const {isEater} = useOrderingExperience();
  const {variant: ez489variant} = useExperiment(Experiments.TypeaheadSearch);

  // ez491
  const {trackExposure: trackExposureEz491} = useExperiment(Experiments.AppBarStepThrough);

  useEffect(() => {
    trackExposureEz491();
  }, [trackExposureEz491]);
  // end ez491

  return (
    <div
      className="flex h-20 items-center justify-between gap-1 overflow-hidden border-b bg-white px-2 text-peppercorn-800 tablet:pl-6 tablet:pr-8"
      data-testid="app-bar"
    >
      {/* --- Left side --- */}
      <div className="flex items-center justify-between gap-3">
        <AppBarIconButton
          aria-label={t('menus.label')}
          className="desktop1061:hidden"
          data-testid="mobile-menu-button"
          icon={faBars}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          onKeyDown={(event: React.KeyboardEvent) =>
            event.key === 'Escape' && setIsMobileMenuOpen(false)
          }
        />

        {isMobileMenuOpen && <MenuModal onDismiss={() => setIsMobileMenuOpen(false)} />}

        <Logo />

        {isLoggedOutLandingPage ? (
          <div className="hidden desktop:block">
            <LoggedOutMenu />
          </div>
        ) : (
          <>
            {!isEater && (
              <div className="relative flex w-full items-center gap-3 tablet:gap-4">
                <div className="hidden desktop:block">
                  <motion.div
                    animate={isSearchBarOpen ? 'hidden' : 'visible'}
                    initial={false}
                    variants={{
                      hidden: {opacity: 0},
                      visible: {opacity: 1},
                    }}
                  >
                    <EventBar onMenuPage={onMenuPage} onSearchPage={onSearchPage} />
                  </motion.div>
                </div>

                {ez489variant === 'treatment' ? (
                  <SearchBarV2
                    isAdmin={isAdmin}
                    isOpen={isSearchBarOpen}
                    setIsOpen={setIsSearchBarOpen}
                  />
                ) : (
                  <SearchBar
                    isAdmin={isAdmin}
                    isOpen={isSearchBarOpen}
                    setIsOpen={setIsSearchBarOpen}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>

      {/* --- Right side --- */}
      <div className="flex items-center gap-2">
        {!isLoggedOutLandingPage && (
          <AppBarIconButton
            aria-label={t('search.input.label')}
            className="desktop1279:hidden"
            data-testid="search-magnifying-glass"
            icon={faMagnifyingGlass}
            onClick={() => {
              if (!onSearchPage && fulfillmentDetail?.id) {
                return router.push(
                  compilePath(SEARCH_ORDER_ID_PATH, {orderId: fulfillmentDetail.id}),
                );
              }
              document?.getElementById('keyword-search-new-app-bar')?.focus();
            }}
            title={t('search.input.label')}
          />
        )}

        {isLoggedOutLandingPage && (
          <div className="mr-2 hidden desktop1279:block">
            <MenuLink
              label={t('navLinks.supportPhoneNumber')}
              href={t('navLinks.supportPhoneNumberTel')}
            />
          </div>
        )}

        <ContactUs />

        <div className="h-8 border-r" />

        <div className="flex items-center gap-2">
          {isLoggedIn ? <User /> : <SignIn />}
          {isLoggedIn && <Notifications isAdmin={isAdmin} notifications={notifications} />}
          <Cart menuPageClickHandler={menuPageClickHandler} />
          {isAdmin && <Admin />}
        </div>
      </div>
    </div>
  );
};

export default NewAppBar;
