import {useEffect, useMemo, useRef, useState} from 'react';
import {Autocomplete, Icon} from '@ezcater/tapas';
import {faMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons';
import {useField, useFormikContext} from 'formik';
import {debounce} from 'lodash-es';
import useTranslation from 'next-translate/useTranslation';
import {twMerge} from 'tailwind-merge';

import {GeocodedAddressResult} from '@/graphql/types';
import useIdentity from '@/hooks/useIdentity';
import useSavedAddressSearch from '@/hooks/useSavedAddressSearch';
import useTracking from '@/hooks/useTracking';
import {useConsumerCart, useDerivedMenuData} from '@/pageComponents/catering-menu/hooks';
import {getElementWidth} from '@/utils/dom';
import {type Address as SavedAddressResult} from '@/utils/googleAutocomplete';
import {useIsDebounceEnabled} from '../DebounceProvider';
import {useGlobalFulfillmentDetail} from '../GlobalFulfillmentDetailProvider';
import {useGoogleMapsAPI} from '../GoogleMapsProvider';
import {AddressAutocompleteListbox} from './AddressSearchAutocompleteListbox';
import AddressSearchAutocompleteOption from './AddressSearchAutocompleteOption';
import AddressSearchAutocompleteSuffix from './AddressSearchAutocompleteSuffix';
import {
  type AddressSearchAutocompleteProps,
  type AutocompleteOption,
  type GetPlacePredictionsArgs,
} from './types';

type AutocompleteChangeReason = 'clear' | 'createOption' | 'selectOption' | 'removeOption' | 'blur';

export default function AddressSearchAutocomplete({
  disabled = false,
  geocodedAddressResultName,
  googlePlaceName,
  id,
  inputName,
  onInputChange,
  savedAddressName,
  shouldIncludeSavedAddresses = false,
  shouldSubmitOnSelect = false,
  slotProps,
  trackingSubcategory = 'address search',
  ...props
}: AddressSearchAutocompleteProps) {
  const {submitForm} = useFormikContext();
  const [, inputMeta, inputHelpers] = useField<string>(inputName);
  const {value: inputValue} = inputMeta;
  const [googlePlaceOptions, setGooglePlaceOptions] = useState<AutocompleteOption[]>([]);
  const [trackBeganSearchKeyword, setTrackBeganSearchKeyword] = useState(true);
  const [trackShownSavedAddresses, setTrackShownSavedAddresses] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [listboxWidth, setListboxWidth] = useState(getElementWidth(inputRef));
  const {libraries} = useGoogleMapsAPI();
  const {fulfillmentDetail} = useGlobalFulfillmentDetail();
  const {t} = useTranslation('common');
  const {track} = useTracking();
  const shouldDebounce = useIsDebounceEnabled();
  const {caterer} = useDerivedMenuData();
  const consumerCart = useConsumerCart();
  const {data: identityData} = useIdentity();
  const corpAccountId = identityData?.me?.consumerAccount?.corporateAccount?.id ?? null;
  const {id: dataCatererId} = caterer ?? {};
  const catererId = dataCatererId ? dataCatererId : null;
  const {
    address,
    eventLocalTime,
    eventName,
    eventOn,
    headcount,
    id: fulfillmentDetailId,
    strategy,
  } = fulfillmentDetail ?? {};

  const miscJson = JSON.stringify({
    consumer_cart_id: consumerCart?.id,
    fulfillment_detail_id: fulfillmentDetailId,
    street: address?.street,
    city: address?.city,
    state: address?.state,
    corpAccountId,
    eventName,
    orderType: strategy,
    eventDate: eventOn,
    eventTime: eventLocalTime,
    headcountNumber: headcount,
  });

  // Formik field helpers
  const [, {value: googlePlace}, googlePlaceHelpers] = useField<AutocompleteOption | null>(
    googlePlaceName,
  );
  const [, , geocodedAddressResultHelpers] = useField<GeocodedAddressResult | null>(
    geocodedAddressResultName,
  );
  const [, , savedAddressHelpers] = useField<SavedAddressResult | null>(savedAddressName as string);

  const {addresses: savedAddresses} = useSavedAddressSearch({
    searchTerm: inputValue || ' ',
    skip: !shouldIncludeSavedAddresses,
    limit: 3,
  });

  const transformedGooglePlaceOptions = googlePlaceOptions.map(option => {
    if (typeof option === 'string') return option;
    return {
      description: option.description,
      group:
        savedAddresses && savedAddresses.length > 0
          ? t('components.AddressSearchAutocomplete.groups.searchResults')
          : '',
      place_id: option.place_id,
      structured_formatting: option.structured_formatting,
    } satisfies AutocompleteOption;
  });

  const transformedSavedAddressOptions = savedAddresses.map(option => {
    if (typeof option === 'string') return option;
    return {
      corporate: option.corporate,
      description: option.secondaryText,
      group: t('components.AddressSearchAutocomplete.groups.savedAddresses'),
      place_id: option.placeId,
      structured_formatting: {
        main_text: option.mainText,
        secondary_text: option.secondaryText,
      },
    } satisfies AutocompleteOption;
  });

  const options: AutocompleteOption[] = useMemo(
    () => [...transformedSavedAddressOptions, ...transformedGooglePlaceOptions],
    [transformedGooglePlaceOptions, transformedSavedAddressOptions],
  );

  const autocompleteService = useMemo(
    () =>
      libraries.places?.AutocompleteService ? new libraries.places.AutocompleteService() : null,
    [libraries.places],
  );

  const fetchGooglePlacePredictions = useMemo(() => {
    const fetcher = (request: GetPlacePredictionsArgs[0], callback: GetPlacePredictionsArgs[1]) => {
      autocompleteService?.getPlacePredictions(request, callback);
    };
    return shouldDebounce ? debounce(fetcher, 400) : fetcher;
  }, [autocompleteService, shouldDebounce]);

  const handleOnChange = (
    newValue: string | AutocompleteOption | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (!newValue || typeof newValue === 'string' || reason === 'blur') return;
    const isSavedAddress =
      newValue.group === t('components.AddressSearchAutocomplete.groups.savedAddresses');
    if (isSavedAddress) {
      savedAddressHelpers.setValue(
        savedAddresses.find(address => address.placeId === newValue.place_id) ?? null,
      );
      googlePlaceHelpers.setValue(null);
      geocodedAddressResultHelpers.setValue(null);
    } else {
      setGooglePlaceOptions([newValue, ...googlePlaceOptions]);
      googlePlaceHelpers.setValue(newValue);
      savedAddressHelpers.setValue(null);
    }
    track('address selected', {
      caterer_id: catererId || null,
      page: window.location.pathname,
      search_keyword: isSavedAddress
        ? (newValue?.structured_formatting.main_text ?? '')
        : (newValue?.description ?? ''),
      sub_category: trackingSubcategory,
      url: window.location.href,
      value: isSavedAddress ? 'saved address' : 'autocomplete address',
      misc_json: miscJson,
    });
    if (shouldSubmitOnSelect) submitForm();
  };

  // Fetch google place predictions
  useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setGooglePlaceOptions(googlePlace ? [googlePlace] : []);
      return;
    }
    fetchGooglePlacePredictions(
      {input: inputValue, types: ['geocode'], componentRestrictions: {country: 'us'}},
      results => {
        if (active) {
          const newOptions: AutocompleteOption[] = [];
          if (googlePlace) newOptions.push(googlePlace);
          if (results) newOptions.push(...(results as unknown as AutocompleteOption[]));
          setGooglePlaceOptions(newOptions);
        }
      },
    );
    return () => {
      active = false;
    };
  }, [fetchGooglePlacePredictions, inputValue, googlePlace]);

  // Set listbox width
  useEffect(() => {
    if (inputRef.current) setListboxWidth(getElementWidth(inputRef));
  }, []);

  // Update listbox width on resize
  useEffect(() => {
    const handleResize = () => {
      if (inputRef.current) setListboxWidth(getElementWidth(inputRef));
    };

    window.addEventListener('resize', handleResize, {capture: false});
    return () => window.removeEventListener('resize', handleResize);
  }, [inputRef]);

  useEffect(() => {
    if (trackShownSavedAddresses && savedAddresses?.length > 0) {
      track('shown saved addresses', {
        caterer_id: catererId || null,
        page: window.location.pathname,
        sub_category: trackingSubcategory,
        url: window.location.href,
        misc_json: miscJson,
      });
      setTrackShownSavedAddresses(false);
    }
  }, [
    track,
    trackShownSavedAddresses,
    savedAddresses?.length,
    catererId,
    miscJson,
    trackingSubcategory,
  ]);

  return (
    <Autocomplete
      autoSelect
      filterOptions={x => x}
      filterSelectedOptions
      freeSolo
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      getOptionKey={option => (typeof option === 'string' ? option : option.place_id)}
      groupBy={(option: AutocompleteOption) => option.group}
      id={id}
      includeInputInList
      inputValue={inputValue}
      noOptionsText={null}
      onChange={(_event, newValue, reason) => handleOnChange(newValue, reason)}
      onClose={() => setTrackShownSavedAddresses(false)}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason === 'blur') return;
        inputHelpers.setValue(newInputValue);
        if (googlePlace?.description !== newInputValue) googlePlaceHelpers.setValue(null);
        if (inputValue !== newInputValue) geocodedAddressResultHelpers.setValue(null);
        if (trackBeganSearchKeyword) {
          track('began entering search keyword for address search', {
            page: window.location.pathname,
            sub_category: trackingSubcategory,
            caterer_id: catererId,
            url: window.location.href,
            misc_json: miscJson,
          });
          setTrackBeganSearchKeyword(false);
        }
        if (onInputChange) onInputChange(newInputValue);
      }}
      onOpen={() => {
        setTrackShownSavedAddresses(true);
      }}
      options={options}
      ref={inputRef}
      renderOption={option => {
        if (typeof option === 'string') return null;
        return <AddressSearchAutocompleteOption key={option.place_id} option={option} />;
      }}
      {...props}
      slotProps={{
        input: {
          'aria-label': t('components.AddressSearchAutocomplete.aria.label'),
          disabled,
          placeholder:
            slotProps?.input?.placeholder || t('components.AddressSearchAutocomplete.placeholder'),
          prefix: slotProps?.input?.prefix || (
            <Icon className="text-peppercorn-600" icon={faMapMarkerAlt} size="xsmall" />
          ),
          suffix: slotProps?.input?.suffix || (
            <AddressSearchAutocompleteSuffix
              geocodedAddressResultHelpers={geocodedAddressResultHelpers}
              inputHelpers={inputHelpers}
              onClear={() => inputHelpers.setValue('')}
              showClearButton={!!inputValue}
            />
          ),
          ...slotProps?.input,
          className: twMerge(
            inputValue === '' ? 'pr-10' : 'pr-16 [&>input]:truncate',
            slotProps?.input?.className,
          ),
        },
        listbox: {
          style: {width: listboxWidth},
          ...slotProps?.listbox,
          className: twMerge('max-h-[400px]', slotProps?.listbox?.className),
        },
        option: {
          ...slotProps?.option,
          className: twMerge('px-2 py-1', slotProps?.option?.className),
        },
      }}
      slots={{listbox: AddressAutocompleteListbox, ...props.slots}}
      value={googlePlace}
    />
  );
}
