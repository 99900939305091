// @ts-strict-ignore
import getConfig from 'next/config';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import {useDirectConnectNotifications} from '@/components/DirectConnectNotificationsProvider';
import useOrderAccounts from '@/components/EventBar/useOrderAccounts';
import StoreUiAppBar from '@/components/LegacyAppBar';
import useContactUsModal from '@/hooks/useContactUsModal';
import useEzRewardsEligibility from '@/hooks/useEzRewardsEligibility';
import useIdentity from '@/hooks/useIdentity';
import useIsMobileCheck from '@/hooks/useIsMobileCheck';
import useNewAppBarExperience from '@/hooks/useNewAppBarExperience';
import useStartANewOrderEligibility from '@/hooks/useStartANewOrderEligibility';
import useTracking from '@/hooks/useTracking';
import MobileCartProvider, {
  useMobileCartContext,
} from '@/pageComponents/catering-menu/MobileCartProvider/MobileCartProvider';
import {CATERING_MENU_PATH, SIGN_IN_PATH, START_NEW_ORDER_PATH} from '@/paths';
import {compilePath, gtm} from '@/utils';
import {POINTS_GIVEN_FOR_REVIEW, POINTS_GIVEN_FOR_SAVING_CATERER} from '../constants';
import NewAppBar from '../NewAppBar';
import {useStoreNextRouter} from '../StoreNextRouter';
import SkipNav from './SkipNav';

const trackGTMEvent = (eventLabel: string) => {
  gtm.dataLayer({
    event: 'track_event_header_nav',
    event_category: 'Navigation',
    event_action: 'Header',
    event_label: eventLabel,
    event_value: 0,
    non_interaction: false,
  });
};

type StoreUiAppBarProps = React.ComponentProps<typeof StoreUiAppBar>;

const AppBar: React.FC<unknown> = () => {
  const {data} = useIdentity();
  const {t} = useTranslation('app-bar');
  const notifications = useDirectConnectNotifications();
  const router = useRouter();
  const {toggleContactUsModal} = useContactUsModal();
  const {track} = useTracking();
  const {shouldRenderSelectOrderAccount} = useOrderAccounts();
  const contactUsButtonClick = () => {
    toggleContactUsModal();
    trackGTMEvent('Contact Us');
  };
  const {
    isOnMenuPage: onMenuPage,
    isOnSearchPage: onSearchPage,
    isOnLoggedOutLandingPage: onLoggedOutLandingPage,
  } = useStoreNextRouter();

  const isMobile = useIsMobileCheck();
  const {isModalOpen: isMobileCartModalOpen, setIsModalOpen: setIsMobileCartModalOpen} =
    useMobileCartContext();

  const menuPageClickHandler =
    isMobile && onMenuPage ? () => setIsMobileCartModalOpen(!isMobileCartModalOpen) : undefined;

  const consumerAccount = data?.me?.consumerAccount;
  const adminAccount = data?.me?.adminAccount;
  const currentUser: StoreUiAppBarProps['currentUser'] = data?.me?.consumerAccount
    ? {
        id: data.me.id,
        canAccessCorpPortal: consumerAccount.canAccessCorpPortal,
        consumerAccount: {
          id: consumerAccount.id,
          firstName: consumerAccount.firstName,
          fullName: consumerAccount.fullName,
          canAccessConcur: consumerAccount.canAccessConcur,
          canAccessEzRewards: consumerAccount.canAccessEzRewards,
          enrolledInEzRewards: consumerAccount.enrolledInEzRewards,
          ezRewardsAccount: consumerAccount.ezRewardsAccount,
          corporateAccountVerified: consumerAccount.corporateAccountVerified,
        },
        hasAccountHierarchy: shouldRenderSelectOrderAccount,
        corporateAccount: consumerAccount.corporateAccount
          ? {
              id: consumerAccount.corporateAccount.id,
              isCharitable: consumerAccount.corporateAccount.isCharitable,
              uuid: consumerAccount.corporateAccount.uuid,
            }
          : null,
        adminAccount: adminAccount
          ? {
              id: adminAccount.id,
            }
          : null,
      }
    : undefined;

  const {shouldShowNewAppBar} = useNewAppBarExperience();

  const {shouldShowStartANewOrderButton, startANewOrderButtonSearchPath} =
    useStartANewOrderEligibility({
      onMenuPage,
      onSearchPage,
    });

  const {
    publicRuntimeConfig: {corpPortalUrl: corpPortalHost},
  } = getConfig();

  const cartId =
    onMenuPage && adminAccount && router.query?.cartId
      ? (router.query?.cartId as string)
      : undefined;

  const ezRewardsEligibility = useEzRewardsEligibility();

  const defaultProps = {
    compileOrderPath: ({orderId, catererUrlId}): string =>
      compilePath(CATERING_MENU_PATH, {catererUrlId: catererUrlId}, {cartId: orderId}),
    onOpen: () => {
      track('consumer-cart-opened');
    },
    onClickOrder: () => {
      track('consumer-cart-order-clicked');
    },
    startNewOrderPath: compilePath(START_NEW_ORDER_PATH),
    loginPath: compilePath(SIGN_IN_PATH, {}, {redirect_url: router.asPath}),
    contactUsNavItemHandler: contactUsButtonClick,
    onStartNewOrderClick: () => trackGTMEvent('Start New Order'),
    menuPageClickHandler,
    notifications,
    supportPhoneNumber: t('navLinks.supportPhoneNumber'),
    simplified: false,
    track,
    currentUser,
    cartId,
    corpPortalHost,
    landingPage: onLoggedOutLandingPage,
    ezRewardsEligibility,
    ez449Eligibility: {
      startANewOrderButtonSearchPath: startANewOrderButtonSearchPath ?? '',
      shouldShowStartANewOrderButton,
    },
    pointsGivenForReview: POINTS_GIVEN_FOR_REVIEW,
    pointsGivenForSavingCaterer: POINTS_GIVEN_FOR_SAVING_CATERER,
    channelPartnerLogoUrl: consumerAccount?.corporateAccount?.channelPartnerLogoUrl ?? undefined,
  } satisfies StoreUiAppBarProps;

  return (
    <MobileCartProvider>
      <SkipNav />
      {shouldShowNewAppBar ? (
        <NewAppBar
          menuPageClickHandler={menuPageClickHandler}
          onMenuPage={onMenuPage}
          onSearchPage={onSearchPage}
        />
      ) : (
        <div data-testid="legacy-app-bar">
          <StoreUiAppBar {...defaultProps} />
        </div>
      )}
    </MobileCartProvider>
  );
};

export default AppBar;
