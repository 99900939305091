import {useEffect} from 'react';
import {useLatest} from 'react-use';
import {
  faArrowRightToBracket,
  faBowlHot,
  faChartColumn,
  faCirclePlus,
  faCog,
  faCommentPlus,
  faCreditCard,
  faFileInvoiceDollar,
  faFileLines,
  faGift,
  faHeart,
  faHouse,
  faLocationDot,
  faLockOpen,
  faMegaphone,
  faMoneyCheckDollar,
  faPencil,
  faReceipt,
  faRightFromBracket,
  faSquarePollVertical,
  faUserGroup,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import isChromatic from 'chromatic/isChromatic';

import {type TrackingFunction} from '@/components/TrackingProvider';
import {useSignOutUserMutation} from '@/graphql/types';
import useEzRewardsForSavedCaterer from '@/hooks/useEzRewardsForSavedCaterer';
import useIdentity from '@/hooks/useIdentity';
import useOrdererConnectionsCheck from '@/hooks/useOrdererConnectionsCheck';
import AppDownloadCTA from '@/pageComponents/app-download-cta/AppDownloadCTA';
import {
  ADDRESSES_PATH,
  ADMIN_PATH,
  CONNECTIONS_PATH,
  CORP_BILLING_PATH,
  CORP_DASHBOARD_PATH,
  CORP_PEOPLE_PATH,
  CORP_REPORTS_PATH,
  CORP_SETTINGS_PATH,
  EXPENSE_REPORTING_PATH,
  EZ_REWARDS_PATH,
  GROUP_POLLS_PATH,
  MY_PROFILE_PATH,
  ORDERS_PATH,
  PAYMENT_METHODS_PATH,
  RECEIPTS_PATH,
  REFER_A_FRIEND_PATH,
  REVIEWS_PATH,
  SAVED_CATERERS_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  START_NEW_ORDER_PATH,
  SUGGEST_CATERER_PATH,
} from '@/paths';
import compilePath from '@/utils/compilePath';
import {CurrentUserType} from '../../CurrentUser.types';
import {
  AppBarLink,
  Divider,
  EzGreenPill,
  GuavaPill,
  LinkContainer,
  PeppercornPill,
  PillNavBarLink,
  SemiSmallEzIcon,
  SignOutButton,
  Wrapper,
} from './MyAccountNav.styles';
import {formatPoints} from './utils';

type MyAccountNavProps = {
  currentUser?: CurrentUserType;
  track: TrackingFunction;
  trackLink: (path: string, title: string) => void;
  area: 'desktop' | 'mobile';
  showMobileItems?: boolean;
  pointsGivenForReview: number;
  pointsGivenForSavingCaterer: number;
  ezRewardsEligibility: {
    canAccessEzRewards: boolean;
    balanceEnabled: boolean;
    earningsEnabled: boolean;
  };
};

const MyAccountNav: React.FC<MyAccountNavProps> = ({
  currentUser,
  showMobileItems = false,
  track,
  trackLink,
  area,
  pointsGivenForReview,
  pointsGivenForSavingCaterer,
  ezRewardsEligibility,
}) => {
  const {data} = useIdentity();
  const consumerAccount = currentUser?.consumerAccount;
  const isLoggedIn = !!consumerAccount;
  const isAdmin = !!currentUser?.adminAccount;
  const {canAccessEzRewards, balanceEnabled, earningsEnabled} = ezRewardsEligibility;
  const [signOut, {client, loading: isSigningOut}] = useSignOutUserMutation();
  const pendingConnectionsCount =
    data?.me?.consumerAccount?.totalPendingUserConnectionInvitations?.totalCount ?? 0;

  const onSignOut = async () => {
    const {data} = await signOut();

    if (data?.signOutUser?.success) {
      // .clearStore will clear the cache but it will not re-fetch the queries like .resetStore()
      // This is to prevent the re-fetch queries from hitting the ErrorBoundary which
      // can result in flashes of "oh crumbs" before redirecting the users
      await client.clearStore();

      if (!isChromatic() && !process.env.STORYBOOK) {
        // Prevent users from using the back button to navigate
        window.location.replace('/');
      }
    }
  };

  const {showEzRewardsForSavedCaterer} = useEzRewardsForSavedCaterer();
  const {ordererConnectionsEnabled} = useOrdererConnectionsCheck();
  const corpAccountUuid = currentUser?.corporateAccount?.uuid;
  const showCorpNav = currentUser?.canAccessCorpPortal && corpAccountUuid;

  const trackRef = useLatest(track);
  useEffect(() => {
    trackRef.current?.('appbar-profile-menu-opened', {
      url: window.location.href,
      misc_json: JSON.stringify({
        area,
      }),
    });
  }, [area, trackRef]);

  return (
    <Wrapper>
      {isLoggedIn ? (
        <>
          <AppBarLink
            href={compilePath(START_NEW_ORDER_PATH)}
            title="Navigates to Home page"
            onClick={() => trackLink(compilePath(START_NEW_ORDER_PATH), 'Home')}
          >
            <LinkContainer>
              <SemiSmallEzIcon icon={faHouse} />
              Home
            </LinkContainer>
          </AppBarLink>
          <AppBarLink
            href={compilePath(ORDERS_PATH)}
            title="Navigates to Orders page"
            onClick={() => trackLink(compilePath(ORDERS_PATH), 'Orders')}
          >
            <LinkContainer>
              <SemiSmallEzIcon icon={faBowlHot} />
              Orders
            </LinkContainer>
          </AppBarLink>
          {(!showEzRewardsForSavedCaterer || !earningsEnabled) && (
            <AppBarLink
              href={compilePath(SAVED_CATERERS_PATH)}
              title="Navigates to Saved restaurants page"
              onClick={() => trackLink(compilePath(SAVED_CATERERS_PATH), 'Saved restaurants')}
            >
              <LinkContainer>
                <SemiSmallEzIcon icon={faHeart} data-testid="heart-icon" />
                Saved restaurants
              </LinkContainer>
            </AppBarLink>
          )}
          <AppBarLink
            href={compilePath(RECEIPTS_PATH)}
            title="Navigates to Receipts page"
            onClick={() => trackLink(compilePath(RECEIPTS_PATH), 'Receipts')}
          >
            <LinkContainer>
              <SemiSmallEzIcon icon={faReceipt} />
              Receipts
            </LinkContainer>
          </AppBarLink>
          <AppBarLink
            href={compilePath(GROUP_POLLS_PATH)}
            title="Navigates to Group polls page"
            onClick={() => trackLink(compilePath(GROUP_POLLS_PATH), 'Group polls')}
          >
            <LinkContainer>
              <SemiSmallEzIcon icon={faSquarePollVertical} />
              Group polls
            </LinkContainer>
          </AppBarLink>
          <Divider />
          {canAccessEzRewards && currentUser && (
            <PillNavBarLink
              href={compilePath(EZ_REWARDS_PATH)}
              title="Navigates to ezRewards page"
              onClick={() => trackLink(compilePath(EZ_REWARDS_PATH), 'ezRewards')}
              justify="between"
            >
              <LinkContainer className="link-container">
                <SemiSmallEzIcon icon={faGift} />
                ezRewards
              </LinkContainer>
              {balanceEnabled && (
                <PeppercornPill>
                  {`${formatPoints(
                    currentUser.consumerAccount.ezRewardsAccount?.balance || 0,
                  )} points`}
                </PeppercornPill>
              )}
            </PillNavBarLink>
          )}
          {showEzRewardsForSavedCaterer && (
            <PillNavBarLink
              href={compilePath(SAVED_CATERERS_PATH)}
              title="Navigates to Saved restaurants page"
              onClick={() => trackLink(compilePath(SAVED_CATERERS_PATH), 'Saved restaurants')}
              justify="between"
            >
              <LinkContainer className="link-container">
                <SemiSmallEzIcon icon={faHeart} data-testid="heart-icon" />
                Saved restaurants
              </LinkContainer>
              {earningsEnabled && (
                <EzGreenPill>{`+${pointsGivenForSavingCaterer} points`}</EzGreenPill>
              )}
            </PillNavBarLink>
          )}
          <PillNavBarLink
            href={compilePath(REVIEWS_PATH)}
            title="Navigates to Reviews page"
            onClick={() => trackLink(compilePath(REVIEWS_PATH), 'Reviews')}
            justify="between"
          >
            <LinkContainer className="link-container">
              <SemiSmallEzIcon icon={faPencil} />
              Reviews
            </LinkContainer>
            {earningsEnabled && <EzGreenPill>{`+${pointsGivenForReview} points`}</EzGreenPill>}
          </PillNavBarLink>
          <PillNavBarLink
            href={compilePath(REFER_A_FRIEND_PATH)}
            title="Navigates to Refer a friend page"
            onClick={() => trackLink(compilePath(REFER_A_FRIEND_PATH), 'Refer a friend')}
            justify="between"
          >
            <LinkContainer className="link-container">
              <SemiSmallEzIcon icon={faMegaphone} />
              Refer a friend
            </LinkContainer>
            {earningsEnabled && <EzGreenPill>+25,000 points</EzGreenPill>}
          </PillNavBarLink>
          <Divider />
          <AppBarLink
            href={compilePath(SUGGEST_CATERER_PATH)}
            title="Navigates to Suggest a restaurant page"
            onClick={() => trackLink(compilePath(SUGGEST_CATERER_PATH), 'Suggest a restaurant')}
          >
            <LinkContainer>
              <SemiSmallEzIcon icon={faCommentPlus} />
              Suggest a restaurant
            </LinkContainer>
          </AppBarLink>
          <AppBarLink
            href={compilePath(MY_PROFILE_PATH)}
            title="Navigates to Account settings page"
            onClick={() => trackLink(compilePath(MY_PROFILE_PATH), 'Account settings')}
          >
            <LinkContainer>
              <SemiSmallEzIcon icon={faCog} />
              Account settings
            </LinkContainer>
          </AppBarLink>
          {ordererConnectionsEnabled && (
            <PillNavBarLink
              href={compilePath(CONNECTIONS_PATH)}
              title="Navigates to Connections page"
              onClick={() => trackLink(compilePath(CONNECTIONS_PATH), 'Connections')}
              justify="between"
            >
              <LinkContainer className="link-container">
                <SemiSmallEzIcon icon={faUsers} />
                Connections
              </LinkContainer>
              {pendingConnectionsCount > 0 && <GuavaPill>{pendingConnectionsCount}</GuavaPill>}
            </PillNavBarLink>
          )}
          <AppBarLink
            href={compilePath(ADDRESSES_PATH)}
            title="Navigates to Delivery addresses page"
            onClick={() => trackLink(compilePath(ADDRESSES_PATH), 'Delivery addresses')}
          >
            <LinkContainer>
              <SemiSmallEzIcon icon={faLocationDot} />
              Delivery addresses
            </LinkContainer>
          </AppBarLink>
          <AppBarLink
            href={compilePath(PAYMENT_METHODS_PATH)}
            title="Navigates to Payment methods page"
            onClick={() => trackLink(compilePath(PAYMENT_METHODS_PATH), 'Payment methods')}
          >
            <LinkContainer>
              <SemiSmallEzIcon icon={faCreditCard} />
              Payment methods
            </LinkContainer>
          </AppBarLink>
          {currentUser?.consumerAccount.canAccessConcur && (
            <AppBarLink
              href={compilePath(EXPENSE_REPORTING_PATH)}
              title="Navigates to Expense reporting page"
              onClick={() => trackLink(compilePath(PAYMENT_METHODS_PATH), 'Expense reporting')}
            >
              <LinkContainer>
                <SemiSmallEzIcon icon={faMoneyCheckDollar} />
                Expense reporting
              </LinkContainer>
            </AppBarLink>
          )}
          <Divider />
          {showCorpNav && (
            <>
              <h1 className="px-4 py-2 font-lato text-xs font-bold uppercase text-gray-400">
                corporate account
              </h1>
              <AppBarLink
                href={compilePath(CORP_DASHBOARD_PATH, {corpAccountUuid})}
                rel="noopener noreferrer"
                target="_blank"
                title="Navigates to corporate dashboard"
                onClick={() =>
                  trackLink(compilePath(CORP_DASHBOARD_PATH, {corpAccountUuid}), 'Corp dashboard')
                }
              >
                <LinkContainer>
                  <SemiSmallEzIcon icon={faChartColumn} />
                  Dashboard
                </LinkContainer>
              </AppBarLink>
              <AppBarLink
                href={compilePath(CORP_BILLING_PATH, {corpAccountUuid})}
                rel="noopener noreferrer"
                target="_blank"
                title="Navigates to corporate billing"
                onClick={() =>
                  trackLink(compilePath(CORP_BILLING_PATH, {corpAccountUuid}), 'Corp billing')
                }
              >
                <LinkContainer>
                  <SemiSmallEzIcon icon={faFileInvoiceDollar} />
                  Billing
                </LinkContainer>
              </AppBarLink>
              <AppBarLink
                href={compilePath(CORP_PEOPLE_PATH, {corpAccountUuid})}
                rel="noopener noreferrer"
                target="_blank"
                title="Navigates to corporate people management"
                onClick={() =>
                  trackLink(
                    compilePath(CORP_PEOPLE_PATH, {corpAccountUuid}),
                    'Corp people and groups',
                  )
                }
              >
                <LinkContainer>
                  <SemiSmallEzIcon icon={faUserGroup} />
                  People &amp; groups
                </LinkContainer>
              </AppBarLink>
              <AppBarLink
                href={compilePath(CORP_REPORTS_PATH, {corpAccountUuid})}
                rel="noopener noreferrer"
                target="_blank"
                title="Navigates to corporate reports"
                onClick={() =>
                  trackLink(compilePath(CORP_REPORTS_PATH, {corpAccountUuid}), 'Corp reports')
                }
              >
                <LinkContainer>
                  <SemiSmallEzIcon icon={faFileLines} />
                  Reports
                </LinkContainer>
              </AppBarLink>
              <AppBarLink
                href={compilePath(CORP_SETTINGS_PATH, {corpAccountUuid})}
                rel="noopener noreferrer"
                target="_blank"
                title="Navigates to corporate account settings"
                onClick={() =>
                  trackLink(compilePath(CORP_SETTINGS_PATH, {corpAccountUuid}), 'Corp settings')
                }
              >
                <LinkContainer>
                  <SemiSmallEzIcon icon={faCog} />
                  Admin Settings
                </LinkContainer>
              </AppBarLink>
              <Divider />
            </>
          )}
          {isAdmin && showMobileItems && (
            <AppBarLink
              href={compilePath(ADMIN_PATH)}
              title="Navigates to Admin page"
              onClick={() => trackLink(compilePath(ADMIN_PATH), 'Admin')}
            >
              <LinkContainer>
                <SemiSmallEzIcon icon={faLockOpen} />
                Admin
              </LinkContainer>
            </AppBarLink>
          )}
          {area === 'mobile' && <AppDownloadCTA isRenderedInAppBar />}
          {consumerAccount && (
            <SignOutButton
              type="button"
              title="Navigates to sign out page"
              onClick={onSignOut}
              disabled={isSigningOut}
            >
              <LinkContainer>
                <SemiSmallEzIcon icon={faRightFromBracket} />
                {`Not ${consumerAccount?.fullName}? Sign out`}
              </LinkContainer>
            </SignOutButton>
          )}
          {area === 'desktop' && <AppDownloadCTA isRenderedInAppBar />}
        </>
      ) : (
        <>
          <AppBarLink
            href={compilePath(SIGN_IN_PATH)}
            title="Navigates to Sign in page"
            onClick={() => trackLink(compilePath(SIGN_IN_PATH), 'Sign in')}
          >
            <LinkContainer>
              <SemiSmallEzIcon icon={faArrowRightToBracket} />
              Sign in
            </LinkContainer>
          </AppBarLink>
          <AppBarLink
            href={compilePath(SIGN_UP_PATH)}
            title="Navigates to Create an account page"
            onClick={() => trackLink(compilePath(SIGN_UP_PATH), 'Create an account')}
          >
            <LinkContainer>
              <SemiSmallEzIcon icon={faCirclePlus} />
              Create an account
            </LinkContainer>
          </AppBarLink>
        </>
      )}
    </Wrapper>
  );
};

export default MyAccountNav;
