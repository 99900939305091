import {type FulfillmentDetailFragment} from '@/graphql/types';
import {type StartNewSearchAddressParams} from '@/utils/geocodedAddressResult';

export const FulfillmentDetailResultToStartNewSearchAddressParams = (
  fulfillmentAddress: FulfillmentDetailFragment,
): StartNewSearchAddressParams => {
  if (!fulfillmentAddress?.address) return {};

  const latLonOptions =
    fulfillmentAddress.address.latitude && fulfillmentAddress.address.longitude
      ? {
          'address[latitude]': fulfillmentAddress.address.latitude.toString(),
          'address[longitude]': fulfillmentAddress.address.longitude.toString(),
        }
      : {};

  return {
    'address[street]': fulfillmentAddress.address.street,
    'address[city]': fulfillmentAddress.address.city,
    'address[state]': fulfillmentAddress.address.state,
    'address[zip]': fulfillmentAddress.address.zip,
    ...latLonOptions,
  };
};
