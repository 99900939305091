import {ChangeEvent, useState} from 'react';
import {NumericFormat} from 'react-number-format';
import {Button, Icon, TextField} from '@ezcater/tapas';
import {faMinus, faPlus} from '@fortawesome/pro-regular-svg-icons';

export type CounterProps = React.PropsWithChildren<{
  id?: string;
  inputValue: number | null;
  onChange: (newValue: number | null) => void;
}>;

const Counter: React.FC<CounterProps> = ({inputValue, onChange, ...props}) => {
  const [value, setValue] = useState(inputValue);
  const buttonClasses =
    'h-10 w-10 hover:bg-peppercorn-800 hover:text-white active:bg-peppercorn-800 active:text-white';

  const onDecrementClick = () => {
    if (value && value > 0) {
      const newValue = value - 1;
      setValue(newValue);
      onChange(newValue);
    }
  };

  const onIncrementClick = () => {
    const newValue = (value || 0) + 1;
    setValue(newValue);
    onChange(newValue);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="flex gap-2">
      <Button
        aria-label="Decrease value"
        className={buttonClasses}
        disabled={!value || value === 0}
        onClick={onDecrementClick}
        variant="outlined"
      >
        <Icon className="shrink-0" icon={faMinus} size="medium" />
      </Button>

      <TextField
        allowNegative={false}
        as={NumericFormat}
        className="h-10 w-16"
        isAllowed={({floatValue}: {floatValue: number | undefined}) => (floatValue || 0) < 1000000}
        onChange={onInputChange}
        refProp="getInputRef"
        value={value}
        {...props}
      />

      <Button
        aria-label="Increase value"
        className={buttonClasses}
        onClick={onIncrementClick}
        variant="outlined"
      >
        <Icon className="shrink-0" icon={faPlus} size="medium" />
      </Button>
    </div>
  );
};

export default Counter;
