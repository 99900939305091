import useIdentity from '@/hooks/useIdentity';

const useEzRewardsEligibility = () => {
  let balanceEnabled = false;
  let canAccessEzRewards = false;
  let earningsEnabled = false;

  const {data: identityData} = useIdentity();
  const {
    canAccessEzRewards: consumerCanAccessEzRewards,
    canEarnReferralRewards,
    canEarnEzRewards,
    enrolledInEzRewards,
  } = identityData?.me?.consumerAccount ?? {};

  // Should see EzRewards
  // This is the main driver for the corp side. It helps prevent non-admin orderers
  // from seeing the balance/accessing ezRewards if their corp account collects all rewards.
  if (consumerCanAccessEzRewards) {
    canAccessEzRewards = true;
  }

  // Should see EzRewards balance
  // If corp keeps all rewards, only owners and admins should see the balance.
  // If corp does not keep rewards, then only eligible individuals can see their balances.
  if (canAccessEzRewards && enrolledInEzRewards) {
    balanceEnabled = true;
  }

  // Should see reward points and should be highlighted
  if (canAccessEzRewards && enrolledInEzRewards && canEarnReferralRewards && canEarnEzRewards) {
    earningsEnabled = true;
  }

  return {canAccessEzRewards, balanceEnabled, earningsEnabled};
};

export default useEzRewardsEligibility;
