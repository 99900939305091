import {SEARCH_NEW_PATH} from '@/paths';
import compilePath from '@/utils/compilePath';
import {geocodedAddressResultToStartNewSearchAddressParams} from '@/utils/geocodedAddressResult';
import useIdentity from './useIdentity';

type EligibilityInputs = {
  onMenuPage: boolean;
  onSearchPage: boolean;
};

type EligibilityReturnValues = {
  startANewOrderButtonSearchPath: string | null;
  shouldShowStartANewOrderButton: boolean;
};

const useStartANewOrderEligibility = ({
  onMenuPage,
  onSearchPage,
}: EligibilityInputs): EligibilityReturnValues => {
  const shouldLoadAddress = !onMenuPage && !onSearchPage;
  const {data: identityData} = useIdentity();

  const userRecommendedDefaultAddress = identityData?.identity?.recommendedDefaultAddress;
  const shouldShowStartANewOrderButton = !!userRecommendedDefaultAddress && shouldLoadAddress;

  const startANewOrderButtonSearchPath = shouldShowStartANewOrderButton
    ? compilePath(
        SEARCH_NEW_PATH,
        {},
        geocodedAddressResultToStartNewSearchAddressParams(userRecommendedDefaultAddress),
        {skipEmptyString: true, skipNull: true},
      )
    : null;

  return {
    startANewOrderButtonSearchPath,
    shouldShowStartANewOrderButton,
  };
};

export default useStartANewOrderEligibility;
