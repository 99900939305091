import {useMemo, useState} from 'react';
import {Button} from '@ezcater/tapas';
import {addMinutes, format, parse} from 'date-fns';
import {twJoin, twMerge} from 'tailwind-merge';

import useIdentity from '@/hooks/useIdentity';

const START_TIME = '5:00 AM';
const START_TIME_ADMIN = '12:00 AM';
const END_TIME = '10:45 PM';
const END_TIME_ADMIN = '11:45 PM';
const INTERVAL = 15;

const getTimeOptions = (isAdmin: boolean) => {
  const start = parse(isAdmin ? START_TIME_ADMIN : START_TIME, 'h:mm a', new Date());
  const end = parse(isAdmin ? END_TIME_ADMIN : END_TIME, 'h:mm a', new Date());
  const timeOptions = [];

  let currentTime = start;
  while (currentTime <= end) {
    timeOptions.push(format(currentTime, 'h:mm a'));
    currentTime = addMinutes(currentTime, INTERVAL);
  }

  return timeOptions;
};

type EventBarTimePickerProps = React.ComponentProps<'div'> & {
  onSelectTime: (time: string) => void;
  selectedTime?: string;
  timesPerPage?: number;
};

const EventBarTimePicker: React.FC<EventBarTimePickerProps> = ({
  onSelectTime,
  selectedTime,
  timesPerPage = 12,
  ...props
}) => {
  const {isAdmin} = useIdentity();
  const timeOptions = useMemo(() => getTimeOptions(isAdmin), [isAdmin]);
  const selectedTimeIndex = timeOptions.findIndex(time => time === selectedTime);
  const pageIndex = Math.floor(selectedTimeIndex / timesPerPage) * timesPerPage;
  const [showIndex, setShowIndex] = useState(selectedTime ? pageIndex : isAdmin ? 48 : 24); // selected page or noon

  return (
    <div {...props} className={twMerge('flex w-[350px] flex-col gap-2', props?.className)}>
      <Button
        className="select-none border-peppercorn-200 font-normal"
        disabled={showIndex <= 0}
        onClick={() => setShowIndex(prev => prev - timesPerPage)}
        size="small"
        variant="outlined"
      >
        Earlier
      </Button>

      <div className="grid grid-cols-4 gap-2">
        {timeOptions.slice(showIndex, showIndex + timesPerPage).map(time => {
          const isSelected = selectedTime === time;
          return (
            <Button
              key={time}
              className={twJoin('border-peppercorn-200 font-normal', isSelected && 'font-bold')}
              onClick={() => {
                if (!isSelected) onSelectTime(time === 'Noon' ? '12:00 PM' : time);
              }}
              size="small"
              variant={isSelected ? 'primary' : 'outlined'}
            >
              {time === '12:00 PM' ? 'Noon' : time}
            </Button>
          );
        })}
      </div>

      <Button
        className="select-none border-peppercorn-200 font-normal"
        disabled={showIndex + timesPerPage >= timeOptions.length}
        onClick={() => setShowIndex(prev => prev + timesPerPage)}
        size="small"
        variant="outlined"
      >
        Later
      </Button>
    </div>
  );
};

export default EventBarTimePicker;
