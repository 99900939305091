import {useEffect} from 'react';

import Experiments from '@/Experiments';
import useExperiment from './useExperiment';
import useEzRewardsEligibility from './useEzRewardsEligibility';

// ez470 - Grant 100 points for saving a caterer that has been ordered from.
const useEzRewardsForSavedCaterer = (shouldTrackExposure = true) => {
  const {earningsEnabled} = useEzRewardsEligibility();
  const {variant, trackExposure} = useExperiment(Experiments.EzRewardsForSavedCaterer);
  const isEzRewardsForSavedCatererTreatment = variant === 'treatment';
  const isEligibleForEzRewardsForSavedCaterer = earningsEnabled;
  const showEzRewardsForSavedCaterer =
    isEzRewardsForSavedCatererTreatment && isEligibleForEzRewardsForSavedCaterer;
  const trackExposureForEzRewardsForSavedCaterer =
    shouldTrackExposure && isEligibleForEzRewardsForSavedCaterer;

  // track exposure by default when hook is called
  useEffect(() => {
    if (trackExposureForEzRewardsForSavedCaterer) {
      trackExposure();
    }
  }, [trackExposure, trackExposureForEzRewardsForSavedCaterer]);

  return {
    isEligibleForEzRewardsForSavedCaterer,
    isEzRewardsForSavedCatererTreatment,
    showEzRewardsForSavedCaterer,
    trackExposure,
  };
};

export default useEzRewardsForSavedCaterer;
