import {useMemo} from 'react';
import {Alert, Button, DatePicker} from '@ezcater/tapas';
import {format, parse, parseISO} from 'date-fns';
import {Form, Formik} from 'formik';
import {capitalize} from 'lodash-es';
import useTranslation from 'next-translate/useTranslation';

import EventBarTimePicker from '@/components/EventBar/EventBarTimePicker';
import {
  useGlobalFulfillmentDetail,
  useGlobalFulfillmentDetailMutation,
} from '@/components/GlobalFulfillmentDetailProvider/GlobalFulfillmentDetailProvider';
import {FulfillmentDetailStrategy} from '@/graphql/types';
import useIsAdmin from '@/hooks/useIsAdmin';
import useMediaQuery from '@/hooks/useMediaQuery';
import {MicroConversionEnum, trackMicroConversion} from '@/utils';
import {convertDateDateToString} from '@/utils/dateFormat';
import {type TrackEvent} from '../../EventBar/types';
import {type DropdownDismissFunction} from './types';

type DateTimeProps = {
  onDropdownDismiss: DropdownDismissFunction;
  onMenuPage?: boolean;
  trackEvent: TrackEvent;
};

const DateTime: React.FC<DateTimeProps> = ({onDropdownDismiss, onMenuPage = false, trackEvent}) => {
  const isAdmin = useIsAdmin();
  const {fulfillmentDetail} = useGlobalFulfillmentDetail();
  const {updateFulfillmentDetail} = useGlobalFulfillmentDetailMutation();
  const {eventLocalTime = null, eventOn = null, strategy} = fulfillmentDetail || {};
  const parsedDate = useMemo(() => (eventOn == null ? null : parseISO(eventOn)), [eventOn]);
  const isTakeout = strategy === FulfillmentDetailStrategy.Takeout;
  const headerStrategy = capitalize(isTakeout ? 'Pickup' : FulfillmentDetailStrategy.Delivery);
  const isLandscape = useMediaQuery('(max-height: 900px)');
  const {t} = useTranslation('app-bar');

  return (
    <Formik
      enableReinitialize
      initialValues={{
        eventDate: eventOn,
        eventTime: eventLocalTime,
      }}
      onSubmit={async (values, formikHelpers) => {
        trackEvent('date-time-search-clicked', {
          eventDate: values?.eventDate,
          eventTime: values?.eventTime,
        });

        // If the date and time haven't changed, only call the change handler
        if (values?.eventDate === eventOn && values?.eventTime === eventLocalTime) {
          return onDropdownDismiss(
            {updated: false},
            {
              eventDate: values?.eventDate,
              eventTime: values?.eventTime,
            },
          );
        }

        // Else, update the fulfillment detail, fire tracking, and call the change handler
        let data: Awaited<ReturnType<typeof updateFulfillmentDetail>>;
        try {
          data = await updateFulfillmentDetail({
            eventLocalTime: values?.eventTime,
            eventOn: values?.eventDate,
          });
        } catch (error) {
          formikHelpers.setStatus({error});
          return;
        }

        trackEvent('date-time-updated', {
          eventDate: values?.eventDate,
          eventTime: values?.eventTime,
        });
        trackMicroConversion(MicroConversionEnum.updateEventDateTime);
        onDropdownDismiss(
          {updated: true, fulfillmentDetailId: data.fulfillmentDetail?.id},
          {
            eventDate: values?.eventDate,
            eventTime: values?.eventTime,
          },
        );
      }}
    >
      {({isSubmitting, setFieldValue, status, values}) => {
        return (
          <Form>
            {status?.error && (
              <Alert className="w-[350px]" variant="error">
                {status.error.message}
              </Alert>
            )}

            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-6 [@media(max-height:900px)]:flex-row">
                <div className="flex flex-col gap-2">
                  <label className="font-bold" htmlFor="event-bar-date">
                    {t('eventBar.eventDate.inputs.date', {strategy: headerStrategy})}
                  </label>

                  <DatePicker
                    className="w-[350px]"
                    defaultViewDate={parsedDate ?? new Date()}
                    id="app-bar-date-picker"
                    inline
                    minDate={isAdmin ? new Date('2000-01-01T00:00:00') : new Date()}
                    onSelectedDateChanged={date =>
                      setFieldValue('eventDate', convertDateDateToString(date))
                    }
                    slotProps={{
                      input: {id: 'event-bar-date'},
                      popup: {className: 'w-full'},
                      root: {className: 'w-full'},
                    }}
                    value={parsedDate}
                  />
                </div>

                <div className="flex w-[350px] flex-col gap-2">
                  <label className="flex items-center gap-1 font-bold" htmlFor="event-bar-time">
                    {t('eventBar.eventDate.inputs.time', {strategy: headerStrategy})}
                  </label>

                  {!isTakeout && (
                    <div className="text-sm leading-tight [@media(max-height:900px)]:mb-1">
                      {t('eventBar.eventDate.helperText')}
                    </div>
                  )}

                  <EventBarTimePicker
                    className="w-[350px]"
                    id="event-bar-time"
                    onSelectTime={(time: string) =>
                      setFieldValue(
                        'eventTime',
                        format(parse(time, 'h:mm a', new Date()), 'HH:mm:ss'),
                      )
                    }
                    selectedTime={
                      values?.eventTime
                        ? format(parse(values.eventTime, 'HH:mm:ss', new Date()), 'h:mm a')
                        : undefined
                    }
                    timesPerPage={isLandscape ? 20 : 12}
                  />
                </div>
              </div>

              <Alert
                className="w-[350px] select-none text-sm leading-tight [@media(max-height:900px)]:w-full"
                variant="info"
              >
                {t('eventBar.eventDate.info')}
              </Alert>
            </div>

            <Button className="mt-4 w-full" loading={isSubmitting} type="submit">
              {onMenuPage ? t('eventBar.eventDate.cta.menu') : t('eventBar.eventDate.cta.search')}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DateTime;
